import APPLE from "../assets/png/apple.png";
import WINDOWS from "../assets/png/windows.png";
import UBUNTU from "../assets/png/ubuntu.png";
import NUM_1 from "../assets/png/icon-green-1.png";
import NUM_2 from "../assets/png/icon-green-2.png";
import NUM_3 from "../assets/png/icon-green-3.png";
import NUM_4 from "../assets/png/icon-green-4.png";
import WINDOWS_STEP_1_IMAGE from "../assets/png/window-worker-install-1.png";
import WINDOWS_STEP_2_IMAGE from "../assets/png/window-worker-install-2.png";
import WINDOWS_STEP_3_IMAGE from "../assets/png/window-worker-install-3.png";
import WINDOWS_STEP_4_IMAGE from "../assets/png/window-worker-install-4.png";
export const BROWSER_WORKER = {
  metadata: {
    description: "Web browsers themselves can serve as DCP compute engines",
    keywords: "Browser Worker, Portal Worker, Worker constructor",
  },
  hero: {
    title: "DCP Browser Worker",
    description1:
      "Unleash the computing power of millions of web-enabled devices with the DCP Browser Worker, compute on anything, anywhere.",
    description2:
      "Replace web ads with in-browser compute as a source of revenue.",
    bgImage:
      "bg-[url(./assets/jpg/screen_saver_worker.jpg)] !bg-cover bg-center !xl:bg-right bg-no-repeat",
  },
  howToGetStarted: {
    cards: [
      {
        title: "PORTAL WORKER",
        img: WINDOWS,
        link1Href:
          "https://updates.distributive.network/downloads/latest/omaha/DCP%20Worker/win/stable/",
        link1Text: "Latest stable .msi",
        buttonText: "LOGIN",
        buttonSubText: "",
        link2Href: "#",
        link2Text: "Documentation",
      },
      {
        title: "HTTPS://DCP.WORK",
        img: APPLE,
        link1Href: "#",
        link1Text: "Convenient 1-click Worker",
        buttonText: "OPEN",
        buttonSubText: "",
        link2Href: "#",
        link2Text: "Documentation",
        comingSoon: false,
      },
      {
        title: "WORKER API",
        img: UBUNTU,
        link1Href: "#",
        link1Text: "Worker Code",
        buttonText: "VIEW",
        buttonSubText: "",
        link2Href: "#",
        link2Text: "Documentation",
        comingSoon: false,
      },
    ],
  },
  tabs: [
    {
      name: "Portal Worker",
      activate: true,
    },
    {
      name: "dcp.work",
      activate: true,
    },
    {
      name: "Worker API",
      activate: true,
    },
  ],
  installationProcedures: [
    // Tab 1 INFO
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to run the DCP Portal Worker and collect Compute Credits.",
      video: "https://www.youtube.com/embed/YF8WnaaRiNk",
      title: "DCP Portal Worker user instructions",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
          Open dcp.cloud and create a DCP Portal account.
          </p>
        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            On the Accounts tab, view and manage your DCP Bank Accounts.
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
            On the Worker tab, select the Bank Account into which earned Compute Credits will be deposited, and how many CPU cores to use concurrently.
          </p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
            Click Start
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
    // Tab 2 INFO
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to use the DCP Browser Worker example at https://dcp.work to compute workloads and collect Compute Credits.",
      video: "https://www.youtube.com/embed/QHvpAtvurQE",
      title: "DCP Portal Worker user instructions",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
          In any browser, open dcp.work to launch a DCP Worker on the Global Network, or open dcp.work/<joinKey> to join a private Compute Group if it exists.
          </p>

        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
          If joining a private Compute Group, you will be prompted for a Join Secret.
          </p>
          <br/>
          <p>
            <span>
            Ex: dcp.work/demo
            </span>
          </p>
          <br/>
          <p>
            Join Secret: dcp
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
          Enter the Bank Account into which earned Compute Credits will be deposited, and how many CPU cores to use concurrently.
          </p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
          Click Start
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
    // Tab 3 INFO
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to embed a DCP Worker into a website using the Worker API so that devices visiting that website become compute nodes.",
      video: "https://www.youtube.com/embed/QHvpAtvurQE",
      title: "Worker API user guide",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
          Open your website’s index.html file.
          </p>
        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
          Paste the code below into the <head> element.
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
            Configure the paymentAccount into which earned Compute Credits will be deposited, how many CPU cores to use concurrently, which Compute Group(s) to join, which events to fire, and more.
          </p>
          <br/>
          <div className="flex w-[100%]">
            <p>Worker API documentation <a className="text-[#14ae5c] href="https://docs.dcp.dev/specs/worker-api.html" target="none" rel="noreferrer">here</a> and jsfiddle example <a className="text-[#14ae5c]" href="https://jsfiddle.net/DCP_team/58cb13xz" target="none" rel="noreferrer">here</a>.</p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
          Devices visiting that website become compute nodes.
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
  ],
  cta: {
    title: "Create your own Compute Group",
    bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
    href: "/contact",
    subHeading:
      "By default, DCP users each have a limited, personal Compute Group allowing up to 3 DCP Workers. Compute Groups with custom join keys (e.g. ‘pixar’ ) with unlimited DCP Worker capacity can be purchased for 500,000 Compute Credits or USD $158.53.",
    ctaText: "Contact Us Today!",
  },
};
