import React, { useState, useEffect } from "react";
import "./PeriodicTable.scss";
import { CAREERS } from "../../PageContent/Careers";

function PeriodicTable() {
  const [elemSize, setElemSize] = useState(150);

  useEffect(() => {
    let vw = window.innerWidth;
    let gaps = 9;
    function handleResize() {
      setElemSize(Math.floor((vw - 0.2 * vw - 7 * gaps) / 10));
    }
    window.addEventListener("resize", handleResize);
    setElemSize(Math.floor((vw - 0.2 * vw - 7 * gaps) / 10));
  }, []);

  return (
    <div className={`periodic-table gap-[2px] sm:gap-[7px] grid grid-cols-10 grid-rows-3 `}>
      {CAREERS.hero.periodicTable.map((element, key) => (
        <div
          className={`element w-[${elemSize}px] ${element.bgColor} relative`}
          key={key}
        >
          <p className="text-[.23rem] md:text-[.7rem] lg:text-[.9rem] absolute top-[.05rem] sm:top-[.2rem] right-[.15rem] sm:right-[.5rem] text-[#BDC8C6]">
            {key < 9 ? `0${key+1}` : key+1}
          </p>
          <h1 className="initials absolute pb-[.3rem] sm:pb-[.5rem] text-[.6rem] xs:text-[.6rem] sm:text-[.8rem] md:text-xl lg:text-4xl xl:text-[3rem] 2xl:text-[3.2rem] 3xl:text-[4rem] font-bold">
            {element?.initials}
          </h1>
          <p className="full-name text-[.23rem] xs:text-[.4rem] sm:text-[.5rem] md:text-[.6rem] lg:text-[.8rem] 2xl:text-[.9rem] font-normal sm:font-bold pt-[0rem] xl:pt-[.5rem] pb-[.2rem] sm:pb-[.4rem] ">
            {element?.name.toUpperCase()}
          </p>
        </div>
      ))}
    </div>
  );
}

export default PeriodicTable;
