import parse from 'html-react-parser'
import React from "react";
import './Footer.scss'
import {FooterModel} from "../../models/footer.model";
import {SocialIconModel} from "../../models/social-Icon.model";
import {NavLink} from "react-router-dom";

export default function Footer(content: FooterModel) {

    return (
        <footer className="footer">
            <div className="footer__addr">
                <img src={content.logo.icon} className='logo' alt={content.logo.alt}/>
                <div className="social flex flex-row">
                    {content.socialIcons?.map((model: SocialIconModel, index) => (
                        <a key={index} href={model.url} target={model.target} rel="noreferrer">
                            {model?.icon}
                        </a>
                    ))}
                </div>
            </div>
            {/* Pages */}
            {content.navigationMenu && (
                <ul className="footer__nav">
                    {content.navigationMenu.map((item, index) => (
                        <li className="nav__item" key={index}>
                            <h2 className="nav__title">
                                {
                                    item.url?.includes('https') || item.url?.includes('http') ?
                                        <a className='nav-links' target={item.target} href={item.url}>
                                            {item.title}
                                        </a> :
                                        <NavLink to={item.url as string} className='nav-links'>
                                            {item.title}
                                        </NavLink>
                                }
                            </h2>
                            {item.subItems && (
                                <ul className="nav__ul">
                                    {
                                        item.subItems.map((subItem, index) => (
                                            <li key={index}>
                                                {
                                                    subItem.url?.includes('https') || subItem.url?.includes('http') ?
                                                        <a className='nav-links' target={subItem.target}
                                                           href={subItem.url}>
                                                            {subItem.title}
                                                        </a> :
                                                        <NavLink to={subItem.url as string} className='nav-links'>
                                                            {subItem.title}
                                                        </NavLink>
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            <div className="legal">
                <div className="line"></div>
                <p>{parse(content.rights as string)}</p>
                <div className="legal__links">
                    <span>
                        <a href={content.terms?.url} target={content.terms?.target} rel="noreferrer">
                            {content.terms?.title}
                        </a>
                    </span>
                    <span>
                        <a href={content.policy?.url} target={content.policy?.target} rel="noreferrer">
                            {content.policy?.title}
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    )
}
