import WebGPUContent, {
  WEB_GPU_SIDE_HERO,
  WEB_GPU_SIDE_INFO,
} from "./Blogs/WebGPUContent";
import PythonMonkeyContent, {
  PYTHON_MONKEY_HERO,
  PYTHON_MONKEY_SIDE_INFO,
} from "./Blogs/PythonMonkeyContent";
import WesContent, { WES_HERO, WES_SIDE_INFO } from "./Profiles/WesContent";
import Erin_Peterson_Content, {
  ERIN_HERO,
  ERIN_SIDE_INFO,
} from "./Profiles/ErinContent";
// ----------------- EDIT CODE HERE ----------------- //
//Description: Used for importing blogs and profile content
//Eg. import WesContent, { WES_HERO, WES_NAME_INFO } from "./Profiles/WesContent";

// ------------------------------ DEFAULT CTA ------------------------------ //
const DEFAULT_CTA = {
  title: "Have a big research computing job, or want to sponsor one?",
  subHeading: `Are you a professor with a large and impactful research computing project that can be launched 
      on DCP? Or is your organization interested in educational philanthropy and willing to sponsor the 
      creation of more exciting and impactful research computing projects like this one?`,
  ctaText: "Contact Us Today!",
  ctaBgColour: "bg-[#17484D]",
  ctaTextColour: "text-[white]",
  bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
  href: "/contact",
};

// ------------------------------ PROFILES ------------------------------ //

// ----------------- EDIT CODE HERE ----------------- //
//Description: Used to specify the url-route name eg. https://distributive.network/profiles/wes
//EDIT: <profile-url> and n+1 (where n is the length of the map + 1)
export const PROFILE_MAP = new Map([
  ["wes", 0],
  ["erin", 1],
  // [<profile-url>, n+1],
]);

// ----------------- EDIT CODE HERE ----------------- //
//Description: Used to add profile content into the list of profiles
//EDIT: Copy the template object and replace <PROFILE> with your imported components
export const PROFILES = [
  // 0. WES
  {
    hero: WES_HERO,
    sidebarContent: WES_SIDE_INFO,
    articleContent: {
      content: WesContent,
    },
    cta: DEFAULT_CTA,
  },
  // 0. Erin
  {
    hero: ERIN_HERO,
    sidebarContent: ERIN_SIDE_INFO,
    articleContent: {
      content: Erin_Peterson_Content,
    },
    cta: DEFAULT_CTA,
  },
  // TEMPLATE:
  // n+1. <PROFILE>
  // {
  //   hero: <PROFILE>_HERO,
  //   sidebarContent: <PROFILE>_INFO,
  //   articleContent: {
  //     content: <PROFILE>Content,
  //   },
  //   cta: <PROFILE>_CTA,
  // },
];

// ----------------- EDIT CODE HERE ----------------- //
//Description: Used to specify the url-route name eg. https://distributive.network/jobs/webgpu
//EDIT: <blog-url> and n+1 (where n is the length of the map + 1)
// ------------------------------ BLOGS ------------------------------ //
export const BLOG_MAP = new Map([
  ["webgpu", 0],
  ["python-monkey", 1],
  // [<blog-url>, n+1],
]);

// ----------------- EDIT CODE HERE ----------------- //
//Description: Used to add blog content into the list of profiles
//EDIT: Copy the template object and replace <BLOG> with your imported components
export const BLOGS = [
  // 0. Web GPU
  {
    hero: WEB_GPU_SIDE_HERO,
    sidebarContent: WEB_GPU_SIDE_INFO,
    articleContent: {
      content: WebGPUContent,
    },
    cta: DEFAULT_CTA,
  },
  // 1. Python Monkey
  {
    hero: PYTHON_MONKEY_HERO,
    sidebarContent: PYTHON_MONKEY_SIDE_INFO,
    articleContent: {
      content: PythonMonkeyContent,
    },
    cta: DEFAULT_CTA,
  },
  // Template:
  // n+1. <BLOG>
  // {
  //   hero: <BLOG>_HERO,
  //   sidebarContent: <BLOG>_SIDE_INFO,
  //   BLOGContent: {
  //     content: <BLOG>Content,
  //   },
  // cta: DEFAULT_CTA
  // },
];
