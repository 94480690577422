import React, { useEffect } from "react";
import HeroPanel from "../HeroPanel/HeroPanel";
import CardPanel from "../CardPanel/CardPanel";
import IconGlassCard from "../Cards/IconGlassCard";
import TextImageSplit from "../TextImageSplit/TextImageSplit";
import CTAPanel from "../CTA/CTAPanel";

import { DIANA } from "../../PageContent/Diana";
import { Helmet } from "react-helmet";
function Diana() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content={DIANA.metadata?.description} />
        <meta name="keywords" content={DIANA.metadata?.keywords} />
      </Helmet>
      <div className="overwatch">
        {/* ------------------------------ Hero Panel ------------------------------ */}
        <HeroPanel
          bgImage={DIANA.hero.bgImage}
          minHeight="min-h-[100vh]  xl:min-h-[520px]"
        >
          <div className="relative xl:top-[0]">
            {/* Title */}
            <div className="overwatch-title relative mb-[1.8rem] top-[0]">
              <img
                src={DIANA.hero.imgTitle}
                alt="DIANA"
                className=" w-[350px] md:w-[430px] "
              />
            </div>
            {/* HERO SUBHEADINGS*/}
            <div className="w-[auto] md:w-[590px] xl:w-[680px]">
              <h2 className="mb-[1rem] text-[1.3rem] md:text-[2rem] xl:!text-[2.25rem] font-[700]">
                {DIANA.hero.subheading1}
              </h2>
              <p className="font-semibold text-[.8rem] md:text-[1.3rem]">
                {DIANA.hero.subheading2}
              </p>
            </div>
          </div>
        </HeroPanel>

        {/* ------------------------------ Why Diana------------------------------ */}

        <CardPanel
          textBlack={true}
          title={DIANA.whyDiana.title}
          parentDivClassName="!pb-[0rem]"
        >
          <div className="flex justify-around flex-wrap w-[100%]">
            {DIANA.whyDiana.benefits.map((benefit, i) => (
              <>
                <IconGlassCard
                  key={i}
                  textBlack={true}
                  title={benefit.title}
                  description={benefit.description}
                  src={benefit.src}
                  noGlass={true}
                  h1ClassName="!tracking-[.1rem] !font-bold"
                  imgClassName="!w-[200px] !2xl:w-[230px] !pb-0"
                  imgDivClassName="min-h-[200px] flex items-center items-center"
                  pClassName="!font-bold w-[250px] 2xl:w-[280px]"
                  divClassName="!justify-start xl:w-[250px] !2xl:w-[100%]"
                  imageFirst={false}
                ></IconGlassCard>
              </>
            ))}
          </div>
        </CardPanel>

        {/* ------------------------------ Proven Technology ------------------------------ */}

        <CardPanel
          textBlack={false}
          title={DIANA.provenTech.title}
          subHeading={DIANA.provenTech.subheading}
          bgImage={`${DIANA.provenTech.bgImage} bg-center-bottom`}
          minHeight="min-h-[60vh]"
          childrenDivClassName="!justify-end"
        >
          <div className="w-[100%] flex flex-wrap justify-center xl:justify-end gap-[1rem] md:gap-[2rem] xl:gap-[3rem] max-w-[2000px] m-auto px-[1rem] md:px-[3rem]">
            {DIANA.provenTech.companies.map((company) => (
              <IconGlassCard
                src={company.src}
                description={company.description}
                pClassName="font-semibold !text-left leading-[1.6rem]"
                imgClassName="!w-[150px]"
                imgDivClassName="!min-h-[96px] flex jusify-center items-center"
                divClassName="justify-start md:w-[320px] xl:w-[350px]"
              >
                <img
                  src={DIANA.provenTech.bgQuotes}
                  alt="Quotes"
                  className="px-[.5rem] absolute bottom-[1rem] left-[50%] translate-x-[-50%] z-[-1]"
                />
              </IconGlassCard>
            ))}
          </div>
        </CardPanel>

        {/* ------------------------------ Optimize Product, factory... ------------------------------ */}

        <CardPanel
          title={DIANA.optimize.title}
          subHeading={DIANA.optimize.subheading}
          bgImage={`${DIANA.optimize.bgImage} bg-cover bg-right`}
          childrenDivClassName=""
        >
          <div className="flex flex-wrap justify-center gap-[1rem] 3xl:gap-[3rem] !px-[1rem] xl:px-0">
            {DIANA.optimize.features.map((feature, i) => (
              <IconGlassCard
                key={i}
                title={feature.title}
                description={feature.description}
                divClassName="justify-start !min-h-[170px] md:w-[360px] xl:w-[290px] 3xl:w-[320px]"
                h1ClassName="min-h-[108px]"
              ></IconGlassCard>
            ))}
          </div>
        </CardPanel>

        {/* ------------------------------ Diana Application ------------------------------ */}
        <TextImageSplit
          title={DIANA.application.title}
          textBgImage="bg-[#E7ECF0]"
          textBlack={true}
          bgImage={`${DIANA.application.bgImage} bg-center !bg-contain bg-no-repeat `}
          imgClassName="!min-h-[400px] "
          parentDivClassName="xl:h-[550px] 3xl:!h-[490px]"
          textClassName="order-2"
        >
          <div className="pt-[1rem] pb-[1.5rem]">
            <h2 className="p_large_style font-bold pb-[.5rem] text-[1.2rem]">
              {DIANA.application.poweredByDCP.title}
            </h2>
            <p className="p_standard_style leading-[1.2rem]">
              {DIANA.application.poweredByDCP.description}
            </p>
          </div>

          <ol className="list-decimal font-bold flex flex-col justify-start items-start gap-[1rem] ml-[1rem] pt-[.5rem]">
            {DIANA.application.processes.map((process, i) => (
              <li key={i} className="p_standard_style leading-[1.2rem]">
                <b>{process.title}</b>{" "}
                <span className="p_standard_style font-normal">
                  {process.description}
                </span>
              </li>
            ))}
          </ol>
        </TextImageSplit>

        {/* ------------------------------ DCP workers supply the compute power ------------------------------ */}
        <CardPanel
          title={DIANA.DCPWorkers.title}
          subHeading={DIANA.DCPWorkers.description}
          bgImage={` ${DIANA.DCPWorkers.bgImage} bg-cover bg-right`}
        >
          <div className="flex flex-wrap justify-center gap-[1rem] 3xl:gap-[3rem]">
            {DIANA.DCPWorkers.features.map((feature, key) => (
              <IconGlassCard
                key={key}
                title={feature.title}
                divClassName="!min-h-[170px] !justify-start md:w-[360px] xl:w-[290px] 3xl:w-[320px] !px-[1rem] md:px-[3rem]"
              >
                <div className="text-sm">
                  <p className="p_standard_style pb-[1.5rem]">
                    <strong>{feature.subheading1}</strong>
                  </p>
                  <p className="p_standard_style">{feature.subheading2}</p>
                </div>
              </IconGlassCard>
            ))}
          </div>
        </CardPanel>

        {/* ------------------------------ CTA ------------------------------ */}
        <CTAPanel
          title={DIANA.cta.title}
          subHeading={DIANA.cta.subheading}
          ctaBgColour="bg-[#2e4c4f]"
          ctaText={DIANA.cta.button}
          ctaTextColour="text-white"
          bgImage="bg-[url(./assets/jpg/factory.jpg)]"
          href={DIANA.cta.href}
        ></CTAPanel>
      </div>
    </>
  );
}

export default Diana;
