import React from "react";
import "./Tabs.scss";
import parse from "html-react-parser";
import { twMerge } from "tailwind-merge";
function Tabs({
  tabs,
  tabClassName,
  children,
  activeTab = 0,
  setActiveTab = () => {
    console.log("No Function set");
  },
}: any) {
  const columnNumber = `grid-cols-${tabs.length}`;
  return (
    <div className=" flex flex-col items-center mt-[65px] w-[100%]">
      {/* Tabs */}
      <div
        className={twMerge(
          `tabs grid ${columnNumber} gap-[11px] justify-center max-w-[3500px] w-[100%] mb-[29px] px-[11px]`,
          tabClassName
        )}
      >
        {tabs?.map((tab: any, i: any) => (
          <div className="relative flex justify-center pb-[13px] self-center h-[100%]">
            <button
              onClick={() => {
                if (tab.activate) setActiveTab(i);
              }}
              className={`h3_style font-bold text-[75px] relative ${
                activeTab === i ? "text-[#000]" : "text-[#A8A8A8]"
              } ${tab.activate ? "" : "!cursor-default"}`}
              key={i}
            >
              {parse(tab.name || "")}
            </button>

            {/* Green Underline */}
            <div
              className={`tabs-underline ${
                activeTab === i ? "block" : "hidden"
              }`}
            ></div>
          </div>
        ))}
      </div>

      {/* Selected Content */}
      {children}
    </div>
  );
}

export default Tabs;
