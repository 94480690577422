import { twMerge } from "tailwind-merge";
import { CardPanelModel } from "../../models/cardPanel.model";
import Margins from "../Margins/Margins";
export default function CardPanel(card: CardPanelModel) {
  return (
    <div
      className={twMerge(
        `card-panel ${card.bgImage} text-center flex flex-col ${
          card.textBlack ? "text-black" : "text-white"
        } pt-[1.5rem] pb-[3rem] bg-no-repeat bg-cover`,
        card.parentDivClassName
      )}
    >
      <Margins className={twMerge(``, card.headingClassName)}>
        {card.title && (
          <h2
            className={twMerge(
              `h2_style text-center px-[0rem] md:px-[2rem] py-[1rem] pb-[1rem] text-xl md:text-4xl font-[700] leading-[1.6rem]`,
              card.titleClassName
            )}
          >
            {card.title}
          </h2>
        )}
        {card.subHeading && (
          <p
            className={twMerge(
              "p_large_style text-center px-[0rem] md:px-[2rem] text-xs md:text-[1em] pb-[2rem] font-normal leading-[1.5rem]",
              card.subheadingClassName
            )}
          >
            {card.subHeading}
          </p>
        )}
      </Margins>

      {card.grid ? (
        <div className={`grid ${card.childrenDivClassName}`}>
          {card.children}
        </div>
      ) : (
        <div
          className={twMerge(
            `flex justify-center ${card.childrenGap} gap-0 md:gap-[2rem] flex-wrap `,
            card.childrenDivClassName
          )}
        >
          {card.children}
        </div>
      )}
    </div>
  );
}
