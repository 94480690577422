import React from "react";
import CLIPBOARD from "../../assets/png/clipboard_black.png";
function CopyToClipboard({ message, copyFunc }: any) {
  return (
    <div className="h-[17px]">
      <button
        className="absolute bottom-[8px] right-[10px]"
        onClick={() => {
          copyFunc(message);
        }}
      >
        <img src={CLIPBOARD} alt="icon" className="w-[18px] h-[18px]"></img>
      </button>
    </div>
  );
}

export default CopyToClipboard;
