export const FRAMEWORK = {
  metadata: {
    description:
      "Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.",
    keywords: "",
  },
  hero: {
    title: "The Distributive Compute Protocol",
    subtitle:
      "Welcome to the dawn of a new era of distributed compute for everyone.",
    description:
      "The Distributive Compute Protocol is a powerful distributed computing platform and ecosystem built on the web stack that connects supply and demand for compute to make everything from cutting edge scientific research to next-generation commercial solutions.",
  },
  banner: {
    title: `The DCP Platform Provides <br className="block sm:hidden"/> compute.for<span className="break-all">(</span><span className='text-[#17CD6C]'>you</span>)`,
    subtitle:
      "A powerful, easy-to-use distrbuted computing platform that harnesses your idle infrastructure",
  },
  platform_cards: {
    dcp: {
      maincard: {
        title: "How DCP Works",
        description: `DCP provides developers with a user-friendly Compute API to express computational jobs, and the DCP Scheduler automatically matches jobs with compute supply on the network. Users attach compute Credits to their workloads at deployment, and DCP Workers earn them when executing and returning results. 
                <br>
                <br>
                DCP Workers can be organized into Compute Groups, allowing for on-prem computing networks, cloud computing networks, or hybrid. DCP’s flexible framework is perfect for both enterprises and individuals.`,
      },
      subcards: [
        {
          title2: "STEP 1",
          description:
            "<strong>job = compute.for(inputSet, workFunction);<strong/>",
          subdescription:
            "Simply specify data and code to distribute with the Compute API",
        },
        {
          title2: "STEP 2",
          description: "<strong>worker.start();<strong/>",
          subdescription:
            "Launch DCP Workers on any number of devices, anywhere",
        },
        {
          title2: "STEP 3",
          description: "<strong>results = await job.exec();<strong/>",
          subdescription: "Launch your job, get your results",
        },
      ],
    },
    cases: {
      maincard: {
        title: "Use Cases",
        description: `Many use cases today need a substantial amount of computing power, such as AI/ML to recognize faces at airports, data analytics to detect banking fraud, and research computing to discover new drugs. Many algorithms and methods underpinning these use cases are perfectly distributable using DCP, such as Monte Carlo methods, hyperparameter searches, graphics rendering, and ensemble calculations to name just a few.
                <br>
                <br>
                Here are a few of the applications:`,
      },
      subcards1: [
        {
          title: "Astrophysics modeling",
        },
        {
          title: "Nuclear reactor optimization",
        },
        {
          title: "Satellite image analysis",
        },
      ],
      subcards2: [
        {
          title: "Robotic guidance",
        },
        {
          title: "Drug discovery",
        },
        {
          title: "Genomic sequencing",
        },
      ],
      subcards3: [
        {
          title: "Machine Vision QA",
        },
        {
          title: "Surgical Block schedule optimization",
        },
        {
          title: "Financial risk modeling",
        },
      ],
    },
    api: {
      maincard: {
        title: "Compute API",
        img: require("../assets/jpg/computefor.jpg"),
        description: `The Compute API is used to deploy jobs to the network. DCP eliminates complexity and allows developers to focus on writing code; no containerization, application virtualization, port configuration, or other nonsense required.
                <br>
                <br>
                Simply specify a data set, your work function, and deploy. Since DCP is built on web technology, developers can write their code once and it will run everywhere.`,
        job: true,
      },
    },
    scheduler: {
      maincard: {
        title: "Scheduler",
        description:
          "Developers only have to worry about their code; the Scheduler takes care of everything else. It slices jobs, distributes slices to eligible workers for execution, collects and returns all results, and ensures that Compute Credits flow correctly from job owners to worker accounts. The DCP Scheduler automates:",
      },
      subcards1: [
        {
          title: "Receiving and slicing Jobs from Clients",
        },
        {
          title: "Authorizing Jobs and Workers by Compute Group",
        },
        {
          title: "Arranging job slices into tasks for delivery to Workers",
        },
      ],
      subcards2: [
        {
          title:
            "Characterizing workload CPU, GPU, bandwidth, and memory requirements",
        },
        {
          title:
            "Identifying workers and workloads that are not performing as expected",
        },
        {
          title: "Receiving and collating Results from Workers",
        },
      ],
    },
    workers: {
      maincard: {
        title: "Workers",
        description: `Workers are secure programs that execute DCP Job Slices. DCP Workers run in every environment, leveraging computing power from in-house computers, enterprise servers, and even IoT devices. Workers can simultaneously run in the cloud, expanding capacity on demand. More Workers complete more Slices, reducing overall Job time through parallelism.
                <br>
                <br>
                Four examples of Workers providing maximum flexibility across environments:`,
      },
      subcards: [
        {
          title: `SCREENSAVER WORKER`,
          img: require("../assets/svg/icon-screensaver.svg").default,
        },
        {
          title: `DOCKER WORKER`,
          img: require("../assets/svg/icon-docker.svg").default,
        },
        {
          title: `STANDALONE WORKER`,
          img: require("../assets/svg/icon-standalone.svg").default,
        },
        {
          title: `BROWSER WORKER`,
          img: require("../assets/svg/icon-browser.svg").default,
        },
      ],
    },
    groups: {
      maincard: {
        title: "Compute Groups",
        description: `A Compute Group is an association between DCP Jobs and Workers. When a user deploys a Job, they specify one or more Compute Groups to which the Job belongs, which can be private or public. The concept of Compute Groups allows for flexible and hybrid architectures that can include both private and public networks that span multiple sites and time zones.`,
      },
      subcards: [
        {
          title2: "PRIVATE",
          img: require("../assets/svg/icon-private.svg").default,
          description:
            "Private Compute Groups allow enterprises to create their own cloud networks by leveraging computers and servers they already own. For example, pharmaceutical companies create private Compute Groups for running molecular simulations in drug discovery research. The use of private Compute Groups drastically reduces cloud computing costs and ensures that computations are performed in a secure and controlled environment.",
        },
        {
          title2: "PUBLIC",
          img: require("../assets/svg/icon-public.svg").default,
          description:
            "Public Compute Groups create open networks for global impact projects to which anyone can contribute computing power. For example, an astronomy research project in a Public Compute Group leverages computing power from individuals all around the world who volunteer their devices in exchange for Compute Credits, executing computations faster together than would a single computer. Anyone with a suitable device and internet connection can contribute to the research effort.",
        },
      ],
    },
    economics: {
      maincard: {
        title: "Compute Economics",
        description: `<strong> Compute power is a commodity. </strong>
                <br>
                <br>
                DCP's compute economics is based on the concept of Compute Credits, which are a unit of account representing a combination of CPU, GPU, bandwidth, and memory resources. Compute Credits are a fair and equitable way to access computing power on DCP networks.`,
      },
      subcards: [
        {
          title2: "DEMAND",
          img: require("../assets/svg/icon-demand.svg").default,
          description:
            "Clients buy and then spend Compute Credits to run Jobs on DCP, and Workers earn them when executing Jobs. The more Compute Credits a Client attaches to their Job, the greater the number of Workers assigned to that Job.",
        },
        {
          title2: "SUPPLY",
          img: require("../assets/svg/icon-supply.svg").default,
          description:
            "As Workers complete a Job, credits are transferred from the Job owner's account to the Workers' accounts. Workers can set a Minimum Wage and will only retrieve Job Slices that pay above that threshold, creating a bid-ask marketplace for compute.",
        },
        {
          title2: "MANAGE",
          img: require("../assets/svg/icon-manage.svg").default,
          description:
            "Active Jobs and Compute Credit balances are displayed in the DCP Portal, and can also serve as an accounting tool for interdepartmental resource consumption in enterprise networks.",
        },
      ],
    },
    security: {
      maincard: {
        title: "Security",
        description:
          "DCP employs multi-layered security measures, and inherits the high security features in modern web platforms, such as DNSSEC, HTTPS, and SSL encryption. The system utilizes digital signatures and encryption to protect sensitive data from interception or tampering.",
      },
      subcards: [
        {
          title2: "PROTECTING THE WORKERS",
          img: require("../assets/svg/icon-workers.svg").default,
          description:
            "Workers execute workloads in secure sandboxes that prohibit most non-core ES2020 functions, and achieve resilience to unwanted I/O by not allowing workloads to read or write files, or participate in arbitrary network communication. The Worker’s architectural design ensures that no security vulnerability could exist, even by accident, which might allow hostile workloads to read sensitive data present on the host machine. ECMAScript does not have the concept of uninitialized memory or arbitrary memory and therefore memory-based attack techniques are not possible.",
        },
        {
          title2: "PROTECTING THE WORKLOADS",
          img: require("../assets/svg/icon-workloads.svg").default,
          description:
            "Clients can make use of a Compute Group to control which Workers are eligible to receive their workloads. Further, they might store the code and data required by their Job on a network resource which is only accessible to these Workers. For example, proprietary algorithms would likely be stored within a firewalled network and accessible only by Workers therein, and proprietary and/or regulated data would be stored in an identical configuration.",
        },
      ],
    },
  },
  footer: {
    title: "Solving the world’s exponential need for computing power",
    subtitle:
      "The Distributive Compute Protocol (DCP) is a new and powerful distributed computing framework that revolutionizes access to computing power for AI/ML, big data analytics, and advanced research computing, simplifying the use of these technologies and making them more accessible than ever.",
    card1: {
      title: `WHY IS DCP BETTER?`,
      description: `The Distributive Compute Protocol (DCP) is a new and powerful distributed computing framework that revolutionizes how organizations access computing power. <br/><br/> <strong>Simplicity</strong>: 4 lines to deploy </br> <strong>Cost:</strong> up to 92% less than cloud <br/> <strong>Security:</strong> process data locally, fuse results globally <br/><br/>Want to learn more? `,
      button: {
        text: `CONTACT US`,
        textColour: `text-white`,
        url: `/contact`,
      },
    },
    card2: {
      title: `IS DCP A SOLUTION FOR ME?`,
      description: `<ul className="list-disc flex flex-col gap-[1rem] ml-[1rem]"><li><b>You have a compute workload</b><br/>DCP is a solution for large enterprises, governments, and individuals alike.</li> <li><b>Your workload must be distributable</b><br/> i.e. an embarrassingly parallel workload consisting of mutually-independent computations.</li> <li><b>Your workload’s source code must be accessible</b><br/> i.e. DCP doesn’t distribute proprietary software, it distributes and executes code.</li></ul> </br> Need more detailed information... go to our: `,
      button: {
        text: `TECHNICAL DOCUMENTATION`,
        textColour: `text-white`,
        url: `https://docs.dcp.dev/`,
      },
    },
    card3: {
      title: `HOW CAN I GET STARTED?`,
      description: `
            Learn how distributed computing could improve more than the bottom line. <br/><br/><strong>INDIVIDUALS:</strong> check out our docs and ask us any question: docs.dcp.dev<br/><br/><strong>ORGANIZATIONS:</strong> contact us to run a no-cost enterprise trial in no more than an afternoon. <br/><br/> We have DCP solution experts ready to help, regardless of industry.
            `,
      description2:
        "INDIVIDUALS: check out our docs and ask us any question: docs.dcp.dev",
      description3:
        "ORGANIZATIONS: contact us to run a no-cost enterprise trial in no more than an afternoon.",
      description4:
        "We have DCP solution experts ready to help, regardless of industry.",
      button: {
        text: `GET IN TOUCH`,
        textColour: `text-white`,
        url: `/contact`,
      },
    },
  },
};
