import Values from "../assets/png/icon-values.png";
import Benefits from "../assets/png/icon-benefits.png";
// import Equality from "../assets/png/icon-equality.png";
import Culture from "../assets/png/icon-culture.png";
export const CAREERS = {
  metadata: {
    description:
      "Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.",
    keywords: "",
  },
  hero: {
    title: "Careers",
    bgImage: "bg-[url(./assets/jpg/hero-hex.jpg)] bg-left bg-cover",
    subTitle:
      "When taking on exciting challenges, it’s important to have great chemistry.",
    periodicTitle: 'These are the "elements" of our team',
    periodicTable: [
      {
        initials: "Dd",
        name: "Dan",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Ds",
        name: "Douglas",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Wg",
        name: "Wesley",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Pr",
        name: "Paul",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Mg",
        name: "Mohammed",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Bh",
        name: "Bryan",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Kb",
        name: "Karen",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Pr",
        name: "Pedro",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Cc",
        name: "Connor",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Nl",
        name: "Neil",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Jt",
        name: "Jack",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Ep",
        name: "Erin",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "As",
        name: "Amir",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Kk",
        name: "Kirill",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Mk",
        name: "Maria",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Sp",
        name: "Stefan",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Er",
        name: "Eddie",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Da",
        name: "Daniel",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Tb",
        name: "Tristan",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Cb",
        name: "Christian",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Ma",
        name: "Mehedi",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Bk",
        name: "Bartosz",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Jm",
        name: "Joash",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Bc",
        name: "Brandon",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Kf",
        name: "Kiana",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Ah",
        name: "Alex",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Dm",
        name: "Duncan",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Je",
        name: "Jason",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Dw",
        name: "Dan",
        bgColor: "bg-[#2A5A55]/[1]",
        textColour: "text-[white]",
      },
      {
        initials: "Ca",
        name: "Caleb",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Nak",
        name: "Nazila",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Sc",
        name: "Smeet",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Gm",
        name: "Gabrielle",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Rm",
        name: "Robert",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "As",
        name: "Arnab",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "As",
        name: "Alexander",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Tt",
        name: "Tom",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
      {
        initials: "Ky",
        name: "Kevin",
        bgColor: "bg-[white]/[.14]",
        textColour: "text-[white]",
      },
    ],
  },
  whyDistributive: {
    title: "Why Distributive",
    subTitle:
      "Our best asset is our team. To make great solutions, we need great people.",
    bgImage: "bg-[url(./assets/jpg/team-working-2022.jpg)]",
    cards: [
      {
        title: "VALUES",
        description:
          "Courage. Ambition. Service. Distributive bravely tackles the unknown to pursue aspirational problems for the betterment of all. We need the courage to handle uncertainty, the ambition to be innovative, and the service to make something cold like compute into something human.",
        src: Values,
      },
      {
        title: "CULTURE",
        description:
          "Nothing is more exciting than working as a part of a great team. Distributive has a culture of collaboration, curiosity, and passion that creates an environment where people can be themselves while feeling at home in a community. <br/><br/> From weekly team lunches to regular events, our culture in work and play makes us who we are.",
        src: Benefits,
      },
      {
        title: "BENEFITS",
        description:
          "Distributive recognizes the power of good benefits. Employees receive plenty of days off, a flexible work-from-home policy, and a reliable benefits package. <br/> <br/> Ensuring the health of our people benefits the health of our whole team.",
        src: Culture,
      },
      // {
      //   title: "DIVERSITY & INCLUSION",
      //   description:
      //     "Lorem Ipsum is standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has",
      //   src: Equality,
      // },
    ],
  },
  joinTeam: {
    title: "Join the team",
    subHeading: `
    We are always looking for talented and ambitious people for full-time employment or internship positions.
    `,
    subHeading2: `
    <br/>
    <br/>
    <span className="font-normal">
    To make great solutions, we need great people and our greatest asset is our team.
    <br/>
    <br/>
    If you believe you share the values of Courage, Ambition, and Service you might belong at
    Distributive.<span/> 
    <br/>
    <br/>
    We’re actively recruiting for:`,
    bgImage:
      "bg-[url(./assets/jpg/team-2022.jpg)] bg-center !bg-cover bg-no-repeat",
    textBgImage:
      "bg-[url(./assets/jpg/green-leftside.jpg)] !bg-cover bg-bottom bg-no-repeat",
    openPositions: [
      {
        name: "Senior Software Engineer",
        icon: "",
        link: "https://www.linkedin.com/jobs/view/3592868395/",
      },
      // { name: "Development Engineer", icon: "" },
      // { name: "Front End Development Engineer", icon: "" },
      // { name: "Regional Enterprise Sales Associate", icon: "" },
      // { name: "A.I / M.L. Specialist", icon: "" },
      // { name: "Marketing", icon: "" },
    ],
  },
  internProgram: {
    title: "Intern Program",
    subHeading:
      "From internships to co-ops and more, we believe in building up our junior members",
    description:
      "The best thing about our interns is that they keep coming back. For years we have believed strongly in developing our junior talent by giving them excellent learning opportunities. Our senior staff have incredible experience and are always passing down wisdom. Interns at Distributive hone their skills while making a real impact towards an inspiring vision.",
    bgImage:
      "bg-[url(./assets/jpg/intern-pitch-queens.jpg)] !bg-cover bg-center bg-no-repeat",
    button: {
      text: "Apply Today",
      url: "mailto:jobs@distributive.network",
    },
    textBgImage:
      "bg-[url(./assets/jpg/green-rightside.jpg)] !bg-cover bg-bottom bg-no-repeat",
  },
  cta: {
    title: "Interested in joining our team?",
    subHeading:
      "<strong>We know the value of having the right people</strong><br/><br/> Reach out to us to apply or learn more. Don’t see the perfect position for you? We understand that many backgrounds can help in their own way, so consider contacting us anyway.",
    ctaText: "Contact Us Today",
    ctaBgColour: "bg-[black]",
    ctaTextColour: "text-[white]",
    bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
    href: "/contact",
  },
};
