import React from "react";
import "./CTAPanel.scss";
import { CTAPanelModel } from "../../models/ctaPanel";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

function CTAPanel(content: CTAPanelModel) {
  return (
    <div
      className={`${content.bgImage} contact max-h-auto md:max-h-[380px] min-h-[380px] flex justify-center items-center`}
    >
      <div className="cta py-[2rem] text-center rounded-0 w-[92vw] sm:w-[72vw] min-h-[250px] max-h-auto md:max-h-[400px] min-w-[300px] md:min-w-[500px] !my-[2.5rem] mx-3 flex flex-col justify-evenly items-center ">
        <div className="pb-[.3rem]">
          <h2 className="h2_style text-xl md:text-2xl xl:text-3xl font-bold pb-[.4rem] px-[2rem]">
            {parse((content.title as string) || "")}
          </h2>
          <p
            className={`p_large_style px-8 py-[15px] leading-[1.4rem] max-w-[1000px] ${content.pClassName} `}
          >
            {parse((content?.subHeading as string) || "")}
          </p>
        </div>
        <NavLink
          to={content.href || "/"}
          target={content.noReferrer? "noreferrer": "_self"}
          className={`cta_style text-[.7rem] md:text-[1.2rem] xl:text-[1.5rem] mx-[2rem] font-semibold font-bold ${content.buttonClassName} ${content.ctaBgColour} ${content.ctaTextColour} py-[.5rem] min-w-[170px] px-[2.5rem] md:px-[3rem] md:px-[4rem]`}
        >
          {parse((content?.ctaText as string) || "")}
        </NavLink>
      </div>
    </div>
  );
}

export default CTAPanel;
