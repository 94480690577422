import React from "react";
import { twMerge } from "tailwind-merge";

function ArticleParagraph({ children, className }: any) {
  return (
    <>
      <p className={twMerge("p_standard_style", className)}>{children}</p>
      <br />
    </>
  );
}

export default ArticleParagraph;
