import React, { useEffect } from "react";
import Article from "../../components/Article/Article";
import { LEGENDRE_PAIRS } from "../../PageContent/LegendrePairs/LegendrePairs";
function LegendrePairs() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Article articleProps={LEGENDRE_PAIRS} />
    </>
  );
}

export default LegendrePairs;
