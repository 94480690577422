import APPLE from "../assets/png/apple.png";
import WINDOWS from "../assets/png/windows.png";
import UBUNTU from "../assets/png/ubuntu.png";
import NUM_1 from "../assets/png/icon-green-1.png";
import NUM_2 from "../assets/png/icon-green-2.png";
import NUM_3 from "../assets/png/icon-green-3.png";
import NUM_4 from "../assets/png/icon-green-4.png";
import WINDOWS_STEP_1_IMAGE from "../assets/png/window-worker-install-1.png";
import WINDOWS_STEP_2_IMAGE from "../assets/png/window-worker-install-2.png";
import WINDOWS_STEP_3_IMAGE from "../assets/png/window-worker-install-3.png";
import WINDOWS_STEP_4_IMAGE from "../assets/png/window-worker-install-4.png";
export const SCREEN_SAVER_WORKER = {
  metadata: {
    description:
      "Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.",
    keywords: "",
  },
  hero: {
    title: "DCP Screen Saver Workers",
    description:
      "Download the right Screensaver Worker for your operating system",
    bgImage:
      "bg-[url(./assets/jpg/screen_saver_worker.jpg)] !bg-cover bg-center !xl:bg-right bg-no-repeat",
  },
  howToGetStarted: {
    cards: [
      {
        title: "WINDOWS",
        img: WINDOWS,
        link1Href:
          "https://updates.distributive.network/downloads/latest/omaha/DCP%20Worker/win/stable/",
        link1Text: "Latest stable .msi",
        buttonText: "DOWNLOAD NOW",
        buttonSubText: "Current Version: 2.1.0",
        link2Href: "#",
        link2Text: "Version Archive",
      },
      {
        title: "MAC OS",
        img: APPLE,
        link1Href: "",
        link1Text: "",
        buttonText: "",
        buttonSubText: "",
        link2Href: "",
        link2Text: "",
        comingSoon: true,
      },
      {
        title: "UBUNTU",
        img: UBUNTU,
        link1Href: "",
        link1Text: "",
        buttonText: "",
        buttonSubText: "",
        link2Href: "",
        link2Text: "",
        comingSoon: true,
      },
    ],
  },
  tabs: [
    {
      name: "WINDOWS",
      activate: true,
    },
    {
      name: "MAC OS<br/> <span className='text-[13px] absolute top-[30px] left-[50%] translate-x-[-50%] w-[100%]'>(Coming Soon) </span>",
      activate: false,
    },
    {
      name: "UBUNTU <br/> <span className='text-[13px] absolute top-[30px] left-[50%] translate-x-[-50%] w-[100%]'>(Coming Soon)</span>  ",
      activate: false,
    },
  ],
  installationProcedures: [
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to install the Windows Screensaver Worker and set it up to join the Global DCP Network and/or one or more Private Compute Groups.",
      video: "https://www.youtube.com/embed/YF8WnaaRiNk",
      title: "Windows DCP Screensaver Worker install procedure",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Set the DCP Bank Account where Compute Credits will be
            deposited as the worker executes work.,
          </p>
          <p>
            <span className="break-all">
              Ex: 0x079DAC0612C710ab4e975dAb7171C7e4beF78c5a
            </span>
            ,
          </p>
          <p>
            Create, view, and manage your Accounts in the DCP Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
            Set the Compute Group(s) from which the worker will fetch
            work. Opt-in/out of the Global Network and join Private
            groups as required.,
          </p>
          <div className="flex w-[100%] gap-[5px]">
            <p>Ex:</p>
            <div className="flex flex-col">
              <p>Join Key: demo</p> <p>Join Secret: dcp</p>
              <p>Label: demo time!</p>
            </div>
          </div>
          ,
          <p>
            Create, view, and manage your Compute Groups in the DCP
            Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
          In ‘Change power settings’, set ‘Turn off the display’ to
          ‘Never’ when Plugged in, and set ‘Put the computer to
          sleep’ to ‘Never’ when Plugged in.,
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
    // MAC OS INSTALLATION PROCEDURE
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to install the Windows Screensaver Worker and set it up to join the Global DCP Network and/or one or more Private Compute Groups.",
      video: "https://www.youtube.com/embed/QHvpAtvurQE",
      title: "Windows DCP Screensaver Worker install procedure",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Set the DCP Bank Account where Compute Credits will be
            deposited as the worker executes work.,
          </p>
          <p>
            <span className="break-all">
              Ex: 0x079DAC0612C710ab4e975dAb7171C7e4beF78c5a
            </span>
            ,
          </p>
          <p>
            Create, view, and manage your Accounts in the DCP Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
            Set the Compute Group(s) from which the worker will fetch
            work. Opt-in/out of the Global Network and join Private
            groups as required.,
          </p>
          <div className="flex w-[100%] gap-[5px]">
            
            <p>Ex:</p>
            <div className="flex flex-col">
              <p>Join Key: demo</p> <p>Join Secret: dcp</p>
              <p>Label: demo time!</p>
            </div>
          </div>
          ,
          <p>
            Create, view, and manage your Compute Groups in the DCP
            Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
          In ‘Change power settings’, set ‘Turn off the display’ to
          ‘Never’ when Plugged in, and set ‘Put the computer to
          sleep’ to ‘Never’ when Plugged in.,
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
    {
      pageHeader: "How to get Started",
      description:
        "Watch this 3 minute video to learn how to install the Windows Screensaver Worker and set it up to join the Global DCP Network and/or one or more Private Compute Groups.",
      video: "https://www.youtube.com/embed/QHvpAtvurQE",
      title: "Windows DCP Screensaver Worker install procedure",
      steps: [
        {
          number: NUM_1,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
          <p>
            Download and install the Windows DCP Screensaver Worker
            .msi
          </p>
        </div>
          `,
          img: WINDOWS_STEP_1_IMAGE,
        },
        {
          number: NUM_2,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
        >
          <p>
            Set the DCP Bank Account where Compute Credits will be
            deposited as the worker executes work.,
          </p>
          <p>
            <span className="break-all">
              Ex: 0x079DAC0612C710ab4e975dAb7171C7e4beF78c5a
            </span>
            ,
          </p>
          <p>
            Create, view, and manage your Accounts in the DCP Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_2_IMAGE,
        },
        {
          number: NUM_3,
          instruction: `                  
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          >
          <p>
            Set the Compute Group(s) from which the worker will fetch
            work. Opt-in/out of the Global Network and join Private
            groups as required.,
          </p>
          <div className="flex w-[100%] gap-[5px]">
            <p>Ex:</p>
            <div className="flex flex-col">
              <p>Join Key: demo</p> <p>Join Secret: dcp</p>
              <p>Label: demo time!</p>
            </div>
          </div>
          ,
          <p>
            Create, view, and manage your Compute Groups in the DCP
            Portal.,
          </p>
        </div>`,
          img: WINDOWS_STEP_3_IMAGE,
        },
        {
          number: NUM_4,
          instruction: `
          <div
          className="!text-[black] text-center lg:text-left mb-[27px]"
          key={key}
          > 
          <p>
          In ‘Change power settings’, set ‘Turn off the display’ to
          ‘Never’ when Plugged in, and set ‘Put the computer to
          sleep’ to ‘Never’ when Plugged in.,
          </p>
          </div>
        `,
          img: WINDOWS_STEP_4_IMAGE,
        },
      ],
    },
  ],
  cta: {
    title: "Network installer methodologies for large organizations",
    bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
    href: "/contact",
    subHeading:
      "The Windows Screensaver Worker can be installed on hundreds of computers simultaneously by using one of our recommended network installer methodologies and a configuration file that we provide.",
    ctaText: "Contact Us Today!",
  },
};
