import "./Button.scss";
import { ButtonModel } from "../../models/button.model";
import { NavLink } from "react-router-dom";
export default function Button(content: ButtonModel) {
  return content.isButton ? (
    <button
      onClick={content.onClick}
      className={`${content.aClassName || ""} text-center  ${
        content.isDigDeeper ? "dig-deeper-button" : "button-container-button"
      } cursor-pointer py-[.5rem] px-[1rem] ${content.bgColour} ${
        content.textColour
      }`}
    >
      {content?.text}
    </button>
  ):(
    <NavLink
      to={content?.url!}
      className={`${content.aClassName || ""} text-center  ${
        content.isDigDeeper ? "dig-deeper-button" : "button-container-button"
      } cursor-pointer py-[.5rem] px-[1rem] ${content.bgColour} ${
        content.textColour
      }`}
    >
      {content?.text}
    </NavLink>
  );
}
