import React from "react";
import parse from "html-react-parser";
import { CardModel } from "../../models/card.model";
import "./IconGlassCard.scss";
import { twMerge } from "tailwind-merge";
import { cn } from "../../utils/utils";

export default function IconGlassCard(card: CardModel) {
  return (
    <>
      <div
        className={cn(
          "flex flex-col items-center justify-center p-[2rem] min-w-[290px] w-[90vw] box-border px-[1.5rem] md:w-[350px] gap-[1.5rem] min-h-[360px] max-h-[650px] relative",
          card.divClassName,
          {
            glass: !card.noGlass,
          }
        )}
      >
        <h3
          className={cn(
            `h3_style icon-card-title !tracking-[0.313rem] font-bold ${
              card.textBlack ? "!text-[black]" : "!text-[white]"
            } ${card.uppercaseTitle ? "uppercase" : "normal-case"} ${
              card.h1ClassName
            } ${card.imageFirst ? "order-2" : "order-1"}`,
            { hidden: !card?.title }
          )}
        >
          {parse((card?.title as string) || "")}
        </h3>
        {card.src && (
          <div
            className={`${card.imgDivClassName} ${
              card.imageFirst ? "order-1" : "order-2"
            }`}
          >
            <img
              src={card.src}
              alt=""
              className={`${card.imgClassName} icon-card-icon w-[90px] pb-[1rem] `}
            />
          </div>
        )}
        <p
          className={cn(
            `p_standard_style icon-card-description text-[white] text-center font-normal p_standard_style ${
              card.imageFirst ? "order-3" : "order-3"
            } ${card.textBlack ? "!text-[black]" : "!text-[white]"} ${
              card.pClassName
            } `,
            { hidden: !card?.description }
          )}
        >
          {parse((card?.description as string) || "")}
        </p>
        <div className="order-4">{card.children}</div>
      </div>
    </>
  );
}
