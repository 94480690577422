import React from "react";
import CardPanel from "../CardPanel/CardPanel";
import ImageGlassCard from "../Cards/ImageGlassCard";
function EmployeeGrid({ numOfCols, title, subHeading, bgImage, leaders }: any) {
  return (
    <div className="leadership">
      <CardPanel
        grid={true}
        childrenDivClassName={`grid-cols-1 sm:grid-cols-2 ${numOfCols} grid-rows-auto gap-y-8 gap-[15px] md:gap-x-[2rem] justify-items-center lg:px-[5rem] mx-[1rem] md:!m-auto max-w-[1472px]`}
        title={title}
        subHeading={subHeading}
        bgImage={bgImage}
      >
        {leaders?.map((card: any, index: any) => (
          <ImageGlassCard
            key={index}
            src={card.src}
            divClassName="min-h-[390px] lg:min-h-[390px] max-h-[390px] lg:max-h-[360px] max-w-[100%] sm:!max-w-[350px]"
          >
            <h5 className="h5_style image-glass-card-title">{card.name}</h5>
            <p className="p_standard_style pb-[.5rem] ">{card.title}</p>
            <p className="p_small_style image-glass-card-description">
              {card.description}
            </p>
            <a className="p_small_style image-glass-card-url" href={card.url}>
              {card.displayUrl}
            </a>
          </ImageGlassCard>
        ))}
      </CardPanel>
    </div>
  );
}

export default EmployeeGrid;
