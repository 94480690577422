import "./TextImageSplit.scss";
import React from "react";
import { ComponentModel } from "../../models/component.model";
import Button from "../../components/Button/Button";
import Margins from "../Margins/Margins";

export default function TextImageSplit(content: ComponentModel) {
  return (
    <>
      <div
        className={`text-image-features-container h-[100%] xl:min-h-[475px] ${content.parentDivClassName} grid grid-cols-1 xl:grid-cols-2 grid-rows-[auto] xl:grid-rows-1 `}
      >
        <div
          className={`text-image-features-content-tabs-container-right min-h-[460px] xl:min-h-[auto] !w-[100%] flex flex-col justify-between ${
            content.isTextLeft ? "xl:order-0" : "xl:order-1"
          } ${content.textBgImage} ${content.textClassName}`}
        >
          <Margins className="min-h-[100%] xl:min-h-[90%]  py-[3rem] flex flex-col justify-start !m-auto">
            <div
              className={`${
                content.textBlack ? "!text-black" : "!text-white"
              } max-w-[900px]`}
            >
              {/* Title */}
              <h1
                className={`font-bold text-[30px] md:text-[40px] ${
                  content.textBlack ? "!text-black" : "!text-white"
                }`}
              >
                {content.title}
              </h1>

              {/* Subheading */}
              <div
                className={`p_large_style ${
                  content.textBlack ? "!text-black" : "!text-white"
                } font-bold font-[400] text-[1rem] ${
                  content.subHeadingClassName
                }`}
              >
                {content.subHeading}
              </div>

              {/* Description */}
              {content.description && (
                <p
                  className={`p_standard_style ${
                    content.textBlack ? "!text-black" : "!text-white"
                  } leading-[1.4rem] pt-[1rem] lg:pt-[2rem] `}
                >
                  {content.description}
                </p>
              )}

              {content.children}
            </div>
            {content.wantButton ? (
              <div className="p_standard_style pt-[2rem]">
                <Button
                  url={content?.button?.url}
                  textColour="text-[white]"
                  text={content?.button?.text}
                  onClick={content?.button?.onClick}
                  isButton={content.isButton}
                />
              </div>
            ) : (
              ""
            )}
          </Margins>
        </div>
        <div
          className={`${content.imgClassName} text-split-image min-h-[400px] max-h-[auto] py-[2rem] w-[100%] bg-cover bg-center ${content.bgImage}`}
        ></div>
      </div>
    </>
  );
}
