import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBe_Kq1qLnLkWFwrT84_m8wN91oo8w4ta8",
  authDomain: "distributiveemails.firebaseapp.com",
  projectId: "distributiveemails",
  storageBucket: "distributiveemails.appspot.com",
  messagingSenderId: "107412186665",
  appId: "1:107412186665:web:f006e9bb8151d3b7ad5e38",
  measurementId: "G-PDJJS8PEC2"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = app.firestore();


