import "./HeroPanel.scss";
import { ComponentModel } from "../../models/component.model";
import Margins from "../Margins/Margins";
import { twMerge } from "tailwind-merge";
import parse from "html-react-parser";
export default function HeroPanel(content: ComponentModel) {
  return (
    <>
      <div
        className={twMerge(
          `hero-panel-container
        ${content.fullHeight ? "min-h-[100vh]" : "min-h-auto"} ${
            content.bgImage
          } bg-[40%] md:bg-[85%] flex flex-col ${
            content.titleOnly ? "justify-end min-h-[35vh]" : "justify-center"
          } ${
            content.minHeight ??
            content.titleOnly ??
            content.fullHeight ??
            "min-h-[60vh]"
          } xl:py-[5rem]`,
          content.parentContainerClassName
        )}
      >
        <Margins
          disableMargins={content.disableMargins}
          className={`hero-panel-content-container hero-panel-content text-white ${
            content.justifyTop ? "justify-start" : "justify-center"
          }`}
        >
          <div
            className={twMerge(
              `hero-panel-content flex flex-col gap-10`,
              content.contentClassName
            )}
          >
            {content.title && <h1 className="h1_style">{content.title}</h1>}
            {content.description && (
              <h2
                className={`h2_hero_style w-[100%] lg:w-[65%] 2xl:2-[65%] 3xl:w-[52%] ${content.subHeadingClassName}`}
              >
                {parse(content.description || "")}
              </h2>
            )}
            {content.children}
          </div>
        </Margins>
      </div>
    </>
  );
}
