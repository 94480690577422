import React from "react";
import Highlight from "react-highlight";
function CodeBlock({ children, langauge }: any) {
  return (
    <>
      <div className="w-[100%] flex justify-start">
        <Highlight className={langauge}>{children}</Highlight>
      </div>

      <br />
    </>
  );
}

export default CodeBlock;
