import React from "react";
// import parse from 'html-react-parser';
import MediaMention from "./MediaMention";
// import Button from "../../Button/Button";
import "./Media.scss";
import MediaStories from "../../../PageContent/MentionContent/MediaStories";
import { MediaMentions } from "../../../models/media-mention.model"
import { ABOUT } from "../../../PageContent/About"

export default function Media() {
    const mediaComponents = MediaStories();

    return (
        // eventually this will be an api call and automized, but for the moment it is hardcoded.
        <div className="flex flex-col justify-evenly gap-[18px]">
            {mediaComponents.slice(-(ABOUT.media.numOfMentions)).reverse().map((media: MediaMentions, index) => (
                <MediaMention date={media.date} source={media.source} headline={media.headline} url={media.url}/>
            ))}
        </div>
    )
}