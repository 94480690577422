import React, { useRef, useEffect, useState } from "react";
import FrameworkFooter from "../../components/FrameworkFooter/FrameworkFooter";
import FrameworkBanner from "../../components/FrameworkBanner/FrameworkBanner";
import FrameworkMainCard from "../../components/FrameworkMainCards/FrameworkMainCard";
import platform_api_big from "../../assets/png/platform-api-big.png";
import platform_api_inputs from "../../assets/png/platform-api-inputs.png";
import platform_api from "../../assets/png/platform-api.png";
import platform_bank from "../../assets/png/platform-bank.png";
import platform_base from "../../assets/png/platform-base.png";
import platform_cloud from "../../assets/png/platform-cloud.png";
import platform_dcp from "../../assets/png/platform-dcp.png";
import platform_globe from "../../assets/png/platform-globe.png";
import platform_scheduler from "../../assets/png/platform-scheduler.png";
import platform_group from "../../assets/png/platform-group.png";
import platform_use_cases from "../../assets/png/platform-use-cases.png";
import platform_user from "../../assets/png/platform-user.png";
import platform_workers_group from "../../assets/png/platform-workers-group.png";
import platform_workers from "../../assets/png/platform-workers.png";
import platform_full_compute_api from "../../assets/png/platform-full-compute-api.png";
import platform_full_compute_economics from "../../assets/png/platform-full-compute-economics.png";
import platform_full_compute_groups from "../../assets/png/platform-full-compute-groups.png";
import platform_full_howitworks from "../../assets/png/platform-full-howitworks.png";
import platform_full_scheduler from "../../assets/png/platform-full-scheduler.png";
import platform_full_usecase from "../../assets/png/platform-full-usecase.png";
import platform_full_full_worker_api from "../../assets/png/platform-full-workers-api.png";
import HeroPanel from "../../components/HeroPanel/HeroPanel";
import "./Framework.scss";
import FrameworkMainSubcards from "../../components/FrameworkMainCards/FrameworkMainSubcards";

import { FRAMEWORK } from "../../PageContent/Framework";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";

function Framework() {
  let components = FRAMEWORK.platform_cards;

  const dcp = useRef<HTMLDivElement>(null);
  const cases = useRef<HTMLDivElement>(null);
  const api = useRef<HTMLDivElement>(null);
  const scheduler = useRef<HTMLDivElement>(null);
  const workers = useRef<HTMLDivElement>(null);
  const groups = useRef<HTMLDivElement>(null);
  const economics = useRef<HTMLDivElement>(null);
  const security = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(0);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1000px)" });

  useEffect(() => {
    if (!isSmallScreen) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let id = parseInt(entry.target.id);
            if (Number.isInteger(id)) {
              setVisible(parseInt(entry.target.id));
            }
          }
        });
      });
      observer.observe(dcp.current!);
      observer.observe(cases.current!);
      observer.observe(api.current!);
      observer.observe(scheduler.current!);
      observer.observe(workers.current!);
      observer.observe(groups.current!);
      observer.observe(economics.current!);
      observer.observe(security.current!);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={FRAMEWORK.metadata?.description}
        />
        <meta
          name="keywords"
          content={FRAMEWORK.metadata?.keywords}
        />
      </Helmet>
      <div className="">
        <HeroPanel
          bgImage="bg-[url(./assets/jpg/hero-dcp.jpg)] !bg-left"
          // fullHeight={true}
          minHeight="!min-h-[80vh]"
        >
          <div className="md:w-[100%] xl:w-[52%] min-w-[290px]">
            <h1 className="h1_style">{FRAMEWORK.hero.title}</h1>
            <h3 className="h2_hero_style pt-8 pb-[3rem] leading-[2rem]">
              {FRAMEWORK.hero.subtitle}
            </h3>
            <h6 className="h6_style leading-[1.3rem]">
              {FRAMEWORK.hero.description}
            </h6>
          </div>
        </HeroPanel>
        <FrameworkBanner></FrameworkBanner>
        {isSmallScreen ? (
          <div>
            <div className={`relative bg-[#0D2438] `}>
              {/* How DCP works */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.dcp.maincard.title}
                  description={components.dcp.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="25vh"
                  num={`${components.dcp.subcards.length}`}
                  data={components.dcp.subcards}
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_howitworks}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* How it works */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.cases.maincard.title}
                  description={components.cases.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards1.length}`}
                  data={components.cases.subcards1}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards2.length}`}
                  data={components.cases.subcards2}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards3.length}`}
                  data={components.cases.subcards3}
                  gridColumns="grid-cols-3"
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_usecase}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Compute API */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  job={components.api.maincard.job}
                  title={components.api.maincard.title}
                  description={components.api.maincard.description}
                ></FrameworkMainCard>
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_compute_api}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Scheduler */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.scheduler.maincard.title}
                  description={components.scheduler.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="16vh"
                  num={`${components.scheduler.subcards1.length}`}
                  data={components.scheduler.subcards1}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="16vh"
                  num={`${components.scheduler.subcards2.length}`}
                  data={components.scheduler.subcards2}
                  gridColumns="grid-cols-3"
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_scheduler}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Workers */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.workers.maincard.title}
                  description={components.workers.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="20vh"
                  num={`${components.workers.subcards.length}`}
                  data={components.workers.subcards}
                  gridColumns="grid-cols-2"
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_full_worker_api}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Compute Groups */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.groups.maincard.title}
                  description={components.groups.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="73vh"
                  num={`${components.groups.subcards.length}`}
                  data={components.groups.subcards}
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_compute_groups}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Compute Economics */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.economics.maincard.title}
                  description={components.economics.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="47vh"
                  num={`${components.economics.subcards.length}`}
                  data={components.economics.subcards}
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_compute_economics}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>

              {/* Security */}
              <div className="pl-2 pr-2 pt-2">
                <FrameworkMainCard
                  mobile={true}
                  height="100%"
                  title={components.security.maincard.title}
                  description={components.security.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="85vh"
                  num={`${components.security.subcards.length}`}
                  data={components.security.subcards}
                />
              </div>

              {/* Platform */}
              <div>
                <img
                  src={platform_full_howitworks}
                  alt="platform_complete"
                  className="w-full h-auto mt-12 mb-16"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="relative bg-[#0D2438] wholePlatform">
            {/* Text Content */}
            {/* How DCP Works */}
            <div className="absolute z-[5] ml-[2%] w-[42%]">
              <div id="0" ref={dcp} className="h-1">
                {" "}
              </div>

              {/* How DCP works */}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.dcp.maincard.title}
                  description={components.dcp.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="30vh"
                  num={`${components.dcp.subcards.length}`}
                  data={components.dcp.subcards}
                  gridColumns="grid-cols-3"
                />
              </div>

              {/* Use Cases */}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.cases.maincard.title}
                  description={components.cases.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards1.length}`}
                  data={components.cases.subcards1}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards2.length}`}
                  data={components.cases.subcards2}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="10vh"
                  num={`${components.cases.subcards3.length}`}
                  data={components.cases.subcards3}
                  gridColumns="grid-cols-3"
                />
              </div>

              <div id="1" ref={cases} className="h-1"></div>
              {/* Compute API*/}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  job={components.api.maincard.job}
                  title={components.api.maincard.title}
                  description={components.api.maincard.description}
                ></FrameworkMainCard>
              </div>

              <div id="2" ref={api} className="h-1">
                {" "}
              </div>
              {/* Scheduler */}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.scheduler.maincard.title}
                  description={components.scheduler.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="16vh"
                  num={`${components.scheduler.subcards1.length}`}
                  data={components.scheduler.subcards1}
                  gridColumns="grid-cols-3"
                />
                <FrameworkMainSubcards
                  height="16vh"
                  num={`${components.scheduler.subcards2.length}`}
                  data={components.scheduler.subcards2}
                  gridColumns="grid-cols-3"
                />
              </div>

              <div id="3" ref={scheduler} className="h-1">
                {" "}
              </div>
              {/* Workers*/}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.workers.maincard.title}
                  description={components.workers.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="20vh"
                  num={`${components.workers.subcards.length}`}
                  data={components.workers.subcards}
                  gridColumns="!grid-cols-4"
                />
              </div>

              <div id="4" ref={workers} className="h-1">
                {" "}
              </div>
              {/* Compute Groups*/}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.groups.maincard.title}
                  description={components.groups.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="67vh"
                  num={`${components.groups.subcards.length}`}
                  data={components.groups.subcards}
                  gridColumns="grid-cols-2"
                />
              </div>

              <div id="5" ref={groups} className="h-1">
                {" "}
              </div>
              {/* Compute Economics */}
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.economics.maincard.title}
                  description={components.economics.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="60vh"
                  num={`${components.economics.subcards.length}`}
                  data={components.economics.subcards}
                  gridColumns="grid-cols-3"
                />
              </div>

              {/* Security */}
              <div id="6" ref={economics} className="h-1">
                {" "}
              </div>
              <div className="">
                <FrameworkMainCard
                  height="100%"
                  title={components.security.maincard.title}
                  description={components.security.maincard.description}
                ></FrameworkMainCard>
                <FrameworkMainSubcards
                  height="75vh"
                  num={`${components.security.subcards.length}`}
                  data={components.security.subcards}
                  gridColumns="grid-cols-2"
                />
              </div>
              <div id="7" ref={security} className="h-1">
                {" "}
              </div>
            </div>

            {/* Platform Images */}
            <div className="sticky top-0 h-[100vh] w-[100vw] overflow-hidden z-0">
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%]`}
                src={platform_base}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [0, 4, 5, 7].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_cloud}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [0, 6, 7].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_bank}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%]`}
                src={platform_dcp}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  visible === 3 ? "opacity-100" : "opacity-0"
                }`}
                src={platform_scheduler}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  visible !== 3 ? "opacity-100" : "opacity-0"
                }`}
                src={platform_globe}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [0, 1, 2, 3, 7].includes(visible)
                    ? "opacity-100"
                    : "opacity-0"
                }`}
                src={platform_user}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [0, 1, 2, 3, 7].includes(visible)
                    ? "opacity-100"
                    : "opacity-0"
                }`}
                src={platform_api}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [2, 3].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_api_big}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  visible === 2 ? "opacity-100" : "opacity-0"
                }`}
                src={platform_api_inputs}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [0, 3, 4, 7].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_workers}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [5].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_workers_group}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  [5].includes(visible) ? "opacity-100" : "opacity-0"
                }`}
                src={platform_group}
                alt=""
              ></img>
              <img
                className={`framework-image absolute translate-y-[14%] right-[-13%] transition duration-1000 ${
                  visible === 1 ? "opacity-100" : "opacity-0"
                }`}
                src={platform_use_cases}
                alt=""
              ></img>
            </div>
          </div>
        )}
        <FrameworkFooter></FrameworkFooter>
      </div>
    </>
  );
}

export default Framework;
