import { Route, Routes } from "react-router-dom";
import React from "react";
import About from "../../pages/About/About";
import Osler from "../../pages/Osler/Osler";
import ComputeEconomics from "../../pages/ComputeEconomics/ComputeEconomics";
import Demo from "../../pages/Demo/Demo";
import Careers from "../../pages/Careers/Careers";
import Contact from "../../pages/Contact/Contact";
import PageNotFound from "../../pages/PageNotFound/PageNotFound";
import Diana from "../Diana/Diana";
import Media from "../../pages/Media/Media";
import News from "../../pages/News/News";
import ContentPage from "../../pages/Generic/ContentPage";
import Framework from "../../pages/Framework/Framework";
import Home from "../../pages/Home/Home";
import Faq from "../../pages/Faq/Faq";
import ScreenSaverWorkers from "../../pages/ScreenSaverWorkers/ScreenSaverWorkers";
import DCPWorkers from "../../pages/DCPWorkers/DCPWorkers";
import Article from "../Article/Article";
import LegendrePairs from "../../pages/LegendrePairs/LegendrePairs";
import DockerWorkers from "../../pages/DockerWorkers/DockerWorkers";
import Blog from "../../pages/Blog/Blog";
import BrowserWorker from "../../pages/BrowserWorker/BrowserWorker";
export default function Content() {
  return (
    <Routes>
      {/* <Route path="/" element={<KingsDS/>} /> */}
      <Route path="/" element={<Home />} />
      {/* <Route path="/solutions" element={<Solutions />} /> */}
      <Route path="/osler" element={<Osler />} />
      <Route path="/about" element={<About />} />
      <Route path="/platform" element={<Framework />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/compute-economics" element={<ComputeEconomics />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/diana" element={<Diana />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/media" element={<Media />} />
      <Route path="/screensaver" element={<ScreenSaverWorkers />} />
      <Route path="/docker" element={<DockerWorkers />} />
      <Route path="/browser" element={<BrowserWorker />} />
      <Route path="/workers" element={<DCPWorkers />} />
      <Route path="/lp117" element={<LegendrePairs />} />
      <Route path="/jobs/:articleName" element={<Article />} />
      {/* <Route path="/profiles/:profileName" element={<Article />} /> */}
      <Route path="/news" element={<News />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/news/:storyid" element={<ContentPage />} />
      <Route path="/people" element={<Blog />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}
