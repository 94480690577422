export const NEWS = {
    pageTitle: "Recent News",
    cta: {
        title: `What to know more about our company?`,
        subHeading: `Ready to start disrupting the industry and save on engineers' time, energy, and expense? Get in touch.`,
        ctaText: "Careers at Distributive",
        ctaBgColour: "bg-[#17484D]",
        ctaTextColour: "text-[white]",
        bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
        href: "/contact"
    }
}