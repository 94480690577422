import React from "react";
import { FAQ } from "../../PageContent/FAQ";
import FaqPanel from "../../components/FaqPanel/FaqPanel";
import HeroPanel from "../../components/HeroPanel/HeroPanel";
import { Helmet } from "react-helmet";

function Faq() {
  return (
    <>
      <Helmet>
        <meta name="description" content={FAQ.metadata?.description} />
        <meta name="keywords" content={FAQ.metadata?.keywords} />
      </Helmet>
      <div>
        <HeroPanel
          fullHeight={false}
          bgImage={FAQ.hero.bgImage}
          title={FAQ.hero.title}
          description={FAQ.hero.subHeading}
        ></HeroPanel>
        <FaqPanel
          topic={FAQ.distributiveQuestions}
          faqBackground={FAQ.faqBackground}
        ></FaqPanel>
        <FaqPanel
          topic={FAQ.computeQuestions}
          faqBackground={FAQ.faqBackground}
        ></FaqPanel>
        <FaqPanel
          topic={FAQ.productQuestions}
          faqBackground={FAQ.faqBackground}
        ></FaqPanel>
      </div>
    </>
  );
}

export default Faq;
