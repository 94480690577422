import React from "react";
import { twMerge } from "tailwind-merge";
function ArticleLink({
  children,
  noUnderline = false,
  noBlankTarget = false,
  href = "",
  className = "",
}: any) {
  return (
    <a
      href={href}
      className={twMerge(
        `${noUnderline ? "no-underline" : "!underline"} `,
        className
      )}
      target={noBlankTarget ? "" : "_blank"}
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

export default ArticleLink;
