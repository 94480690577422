import platform_base from "../assets/png/homepage-platform-base.png";
import platform_background from "../assets/png/homepage-platform-background.jpg";
import platform_bank from "../assets/png/homepage-platform-1bank.png";
import platform_servers from "../assets/png/homepage-platform-2servers.png";
import platform_dcp from "../assets/png/homepage-platform-3dcp.png";
import platform_user from "../assets/png/homepage-platform-4user.png";
import platform_workers from "../assets/png/homepage-platform-5workers.png";
import platform_complete from "../assets/png/homepage-platform-complete.png";
import platform_nobg from "../assets/png/homepage-platform-complete-nobg.png";
import OSLER_LOGO from "../assets/svg/osler-logo.svg";

export const HOME = {
  metadata: {
    description:
      "Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.",
    keywords: "",
  },
  hero: {
    title: "compute.for",
    bgImage:
      "bg-[url(./assets/jpg/hero-code-mobile.jpg)] xl:bg-[url(./assets/jpg/hero-code.jpg)] bg-bottom sm:bg-bottom xl:bg-center lg:bg-[0] bg-cover bg-no-repeat",
    computeForList: [
      "healthcare",
      "finance",
      "genomics",
      "science",
      "robotics",
      "energy",
      "logistics",
      "research",
      "everyone",
    ],
    subheading:
      "Distributed computing solves tomorrow's greatest challenges by making compute accessible for everyone",
    subheadingDescription:
      "We created the Distributive Compute Protocol (DCP). A web standard for matching supply and demand for compute to enable everyone, from academia to industry, to access easy, affordable and abundant computing resources to power their AI, data analytics, and research computing solutions.",
  },
  platform: {
    title: "The DCP Platform provides compute.for(everyone)",
    video: "https://www.youtube.com/embed/xfir_NNIf0I",
    description:
      "Distributed computing is a type of computing where a large task is broken down into smaller parts and distributed across multiple computers that work together to solve the problem faster. DCP makes it easy for users to express large computing problems, from modeling the outcome of an election to simulating blood flow in the Aorta.",
    howItWorks: "This is how it works:",
    images: {
      background: platform_background,
      base: platform_base,
      bank: platform_bank,
      servers: platform_servers,
      dcp: platform_dcp,
      user: platform_user,
      workers: platform_workers,
      complete: platform_complete,
      completenobg: platform_nobg,
    },
    navLink: "/platform",
    navText: "DIG DEEPER INTO THE PLATFORM",
    tabs: [
      {
        name: "DEPLOY",
        title: "Compute API",
        text: `The Compute API allows solution developers to express computational workloads in only 4 lines of code.
                inputSet: enumerable array of arbitrary data inputs (ex: numerical values, .csv files, images, audio data, etc)

                workFunction: source code of the function and its dependencies that will be applied to each element of the inputSet (ex: Yolov7 machine vision model, astrophysics simulation code, etc)

                job = compute.for(inputSet, workFunction): the job is a mapping of the workFunction to each element of the inputSet, each a job slice
                
                results = await job.exec(): distributes the job slices for execution across many DCP workers
                
                DCP can be called from within existing [node and web] applications to transparently parallelize and distribute the compute-intensive parts`,
      },
      {
        name: "COMPUTE",
        title: "DCP Worker",
        text: `DCP Workers are the secure runtimes that execute DCP job slices on host machines.

                DCP is built on web standards that are compatible with every device on the planet. DCP can run computational workloads on any suitable device, from enterprise servers to smart fridges. DCP is OS- and hardware-agnostic, and computes bitwise identical results across heterogeneous platforms.

                DCP workers connect to the DCP Scheduler over standard web communication protocols to retrieve and execute job slices.

                Google, Microsoft, and Apple spent billions making the web tech used by DCP fast and ultra secure. Web tech, and therefore DCP, is forwards and backwards compatible, forever.

                More workers means faster job completion.`,
      },
      {
        name: "ORGANIZE",
        title: "Compute Groups",
        text: `Compute Groups are associations between DCP jobs and Workers.

                When a user deploys a job, they specify a Compute Group, which can be private or public.

                DCP Workers join Compute Groups using a joinKey and joinSecret, and become eligible to perform those jobs. The concept of Compute Groups allows for flexible and hybrid architectures.

                A Compute Group without a JoinSecret creates an open, DCP Public network for global impact projects like astronomy and cancer research where anyone can contribute computing power.

                Compute Groups with joinSecrets create private, DCP Enterprise networks for industry. Enterprises can create their own clouds by connecting 20 to 20,000 computers they already own on their existing networks.`,
      },
      {
        name: "PAY / GET PAID",
        title: "DCP Bank",
        text: `Computing power is measured in terms of Compute Credits and displayed in DCP Bank accounts. Compute Credits are a combination of CPU, GPU, memory, and bandwidth resources.

                When a user deploys a job, they pay for it with Compute Credits they’ve purchased or earned. When a worker computes a job, they earn Compute Credits that they can spend on jobs or redeem for cash.

                The more Compute Credits a user attaches to their job, the higher execution priority it has on the network. The DCP Scheduler distributes job slices to eligible workers. As workers return results, Compute Credits are transferred from the job owner’s DCP Bank account to the workers’ accounts.

                On DCP Public networks, Compute Credits provide a mechanism for fair and equitable access to computing power. On DCP Enterprise networks, Compute Credits provide an accounting tool for interdepartmental resource consumption.`,
      },
    ],
  },
  solution1: {
    title: `Solution Spotlight`,
    solutionImage: OSLER_LOGO,
    textBgImage:
      "bg-[url(./assets/jpg/green-leftside.jpg)] !bg-cover bg-no-repeat bg-bottom",
    bgImage: "bg-[url(./assets/jpg/surgery-01.jpg)] !bg-cover bg-no-repeat",
    subheading: `Block schedule creation software with surgical precision.`,
    description:
      "Osler creates optimized surgical block schedules in minutes, allowing for increased throughput, decreased surgical peaks, and an easier staff workflow.",
    button: {
      text: "LEARN MORE NOW",
      colour: "text-white",
      url: "/osler",
    },
  },
  solution2: {
    title: `Solution Spotlight`,
    subheading: `Powerful, affordable, on-premise computer vision.`,
    textBgImage:
      "bg-[url(./assets/jpg/green-rightside.jpg)] !bg-cover bg-bottom bg-no-repeat",
    bgImage:
      "bg-[url(./assets/jpg/hero-diana.jpg)] !bg-cover bg-no-repeat",
    description:
      "From forklift detection to face blurring, tomorrow’s digitally-transformed industries use computer vision. Diana allows technology teams to build and operationalize low-cost, secure, and on-prem solutions with DCP Enterprise.",
    button: {
      text: "LEARN MORE NOW",
      colour: "text-white",
      url: "/diana",
    },
  },
  joinTheTeam: {
    title: `Join the team`,
    subheading: ``,
    textBgImage:
      "bg-[url(./assets/jpg/green-leftside.jpg)] !bg-cover bg-bottom bg-no-repeat",
    bgImage:
      "bg-[url(./assets/jpg/lecture-room.jpg)] !bg-cover bg-center bg-no-repeat",
    button: {
      text: "LEARN MORE NOW",
      textColour: "text-white",
      url: "/careers",
    },
  },
};
