import React from "react";
import { CardModel } from "../../models/card.model";
import "./ImageGlassCard.scss";

export default function ImageGlassCard(card: CardModel) {
  return (
    <div
      className={`${card.divClassName} image-glass-card-container w-[90%] sm:w-[100%] max-w-[450px]`}
    >
      <img
        src={card.src}
        alt=""
        className="image-glass-card-icon mt-[-100px] w-[200px] h-[200px] sm:-mt-[115px] sm:w-[230px] sm:h-[230px]"
      />
      <div className="image-glass-card-text relative top-[18px]">
        <h5 className="h5_style image-glass-card-title">{card.title}</h5>
        <p className="p_small_style image-glass-card-description">
          {card.description}
        </p>
        <a
          className="p_small_style image-glass-card-url"
          href={"https://ca." + card.url}
        >
          {card.url}
        </a>
        {card.children}
      </div>
    </div>
  );
}
