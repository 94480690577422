import HeroPanel from "../../components/HeroPanel/HeroPanel";
import { useParams } from "react-router-dom";
import NewsStories from "../../PageContent/MentionContent/NewsStories";
import PageNotFound from "../PageNotFound/PageNotFound";
import React, { useState, useEffect } from "react";
import Margins from "../../components/Margins/Margins";
import parse from "html-react-parser";
import Button from "../../components/Button/Button";
import CTAPanel from "../../components/CTA/CTAPanel";
import { NEWS } from "../../PageContent/NewsList";

export default function ContentPage() {
  const components = NewsStories();
  const { storyid } = useParams();
  const [article, setArticle] = useState(false);
  const [img, setImg] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    for (let i = 0; i < components.length; i++) {
      if (storyid === components[i].id) {
        if (components[i].image) {
          setImg(true);
        }
        setArticle(true);
        setId(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {article ? (
        <>
          <HeroPanel
            bgImage="bg-[url(./assets/jpg/green-leftside.jpg)]"
            fullHeight={false}
            titleOnly={true}
            title={components[id].title}
          />
          <Margins className="flex flex-col lg:flex-row justify-between py-[2.5rem]">
            <div className="lg:hidden block">
              <h1 className="h2_style">
                {components[id].headline}
              </h1>
              <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40 my-[0.5rem]" />
              <p className="p_standard_style mb-[1rem]">{components[id].subtitle}</p>
              <p className="mb-[1rem] !font-light font-italic p_standard_style">
                {components[id].date?.toLocaleString('default', { month: 'long' }) + ` ` + components[id].date?.getDate() + `, ` + components[id].date?.getFullYear()}
              </p>
            </div>
            <div className="content-page-text w-[100%] lg:w-[60%] lg:pr-[4rem] order-2 lg:order-1">
              <div className="lg:block hidden">
                <h1 className="h2_style lg:block hidden">
                  {components[id].headline}
                </h1>
                <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40 my-[0.5rem]" />
                <p className="p_standard_style mb-[1rem]">{components[id].subtitle}</p>
                <p className="mb-[2rem] !font-light font-italic p_standard_style">
                {components[id].date?.toLocaleString('default', { month: 'long' }) + ` ` + components[id].date?.getDate() + `, ` + components[id].date?.getFullYear()}
                </p>
              </div>
              <p className="p_standard_style mb-[2rem]">
                {components[id].content
                  ? parse(components[id].content as string)
                  : ""}
              </p>
              {components[id].about ? (
                <div className="p_small_style">
                  {parse(components[id].about as string)}
                </div>
              ) : (<></>)}
              <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40 my-[1.5rem]" />
              <Button
                url="/contact"
                text="CONTACT US FOR MORE INFO"
                textColour="text-white !bg-[#A8A8A8]"
              />
            </div>
            {img ? (
            <>
              <div className="w-[100%] lg:w-[40%] static lg:relative top-0 lg:top-[-180px] flex flex-col order-1 lg:order-2">
                <img
                  className="w-full h-[500px] object-cover border-solid border-[8px] lg:border-[14px ] border-gray-300 border-opacity-40"
                  src={components[id].image}
                  alt=""
                ></img>
                <p className="text-black lg:pl-[14px] pt-[0.25rem] pb-[1.5rem] lg:pt-[1rem] italic font-light text-sm">
                  {components[id].caption}
                </p>
              </div>
            </>
            ) : (<></>)}
          </Margins>
          <CTAPanel
            title={NEWS.cta.title}
            subHeading={NEWS.cta.subHeading}
            ctaText={NEWS.cta.ctaText}
            ctaBgColour={NEWS.cta.ctaBgColour}
            ctaTextColour={NEWS.cta.ctaTextColour}
            bgImage={NEWS.cta.bgImage}
          />
        </>
      ) : (
        <>
          <PageNotFound />
        </>
      )}
    </>
  );
}