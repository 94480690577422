import MAIL_ICON from "../../assets/svg/mail_icon.svg";
import LINKEDIN_ICON from "../../assets/svg/linkedin_icon.svg";
import WEBSITE_ICON from "../../assets/png/website_icon.png";
import GITHUB from "../../assets/svg/github_icon.svg";
import TWITTER from "../../assets/svg/twitter_icon.svg";
import LegendrePairsContent from "./LegendrePairsContent";

export const LEGENDRE_PAIRS = {
  hero: {
    title: "The Hunt for a Legendre Pair of Length 117",
    description:
      "Though mathematically proven to exist, actually finding one could take up to 20 billion years using a single processor... Welcome to the most extensive search of all time!",
    bgImage:
      "bg-[url(./assets/png/article_backgrounds.png)] bg-cover bg-no-repeat !bg-center",
  },
  sidebarContent: {
    date: "Sep 5, 2023",
    firstName: "Ilias S.",
    lastName: "Kotsireas",
    tableOfContents: "Table of Contents",
    aboutTheAuthor: "About the Author",
    authorImg: "/legendre_pairs/Ilias.png",
    aboutTheAuthorInfo:
      "Professor, Director of the CARGO Lab, Laurier University",
    contactLinks: "Contact Links",
    contactLinksList: [
      // Email
      {
        icon: MAIL_ICON,
        displayName: "ikotsire@wlu.ca",
        link: "mailto:ikotsire@wlu.ca",
      },
      // LinkedIn
      {
        icon: LINKEDIN_ICON,
        displayName: "LinkedIn",
        link: "https://www.linkedin.com/in/ilias-kotsireas-092386b0/",
      },
      // Github
      {
        github: GITHUB,
        displayName: "",
        link: "",
      },
      // Twitter
      {
        icon: TWITTER,
        displayName: "",
        link: "",
      },
      // University Website
      {
        icon: WEBSITE_ICON,
        displayName: "University Website",
        link: "https://www.wlu.ca/academics/faculties/faculty-of-science/faculty-profiles/ilias-s-kotsireas/index.html",
      },
      // Personal Website
      {
        icon: WEBSITE_ICON,
        displayName: "Personal Website",
        link: "https://web.wlu.ca/science/physcomp/ikotsireas/",
      },
    ],
    articles: "Articles",
    articleLinks: [
      {
        name: "Legendre pairs of lengths ℓ ≡ 0 (mod 3)",
        link: "https://onlinelibrary.wiley.com/doi/abs/10.1002/jcd.21806",
      },
      {
        name: "On Hadamard conjecture",
        link: "https://arxiv.org/pdf/cs/0604050.pdf",
      },
    ],
    keywords: "Keywords",
    keywordsList: [
      "Mathematics",
      "Legendre Pairs",
      "Combinatorics",
      "Parallel search",
    ],
  },

  articleContent: {
    video: "https://www.youtube.com/embed/4KYSYsy4yW4?si=z1wGpP9ufbib1xJV",
    content: LegendrePairsContent,
  },
  cta: {
    title: "Have a big research computing job, or want to sponsor one?",
    subHeading: `Are you a professor with a large and impactful research computing project that can be launched 
        on DCP? Or is your organization interested in educational philanthropy and willing to sponsor the 
        creation of more exciting and impactful research computing projects like this one?`,
    ctaText: "Contact Us Today!",
    ctaBgColour: "bg-[#17484D]",
    ctaTextColour: "text-[white]",
    bgImage: "bg-[url(./assets/jpg/lecture-room.jpg)]",
    href: "/contact",
  },
};
