import React from "react";
import MAIL_ICON from "../../../assets/svg/mail_icon.svg";
import LINKEDIN_ICON from "../../../assets/svg/linkedin_icon.svg";
import WEBSITE_ICON from "../../../assets/png/website_icon.png";
import GITHUB from "../../../assets/svg/github_icon.svg";
import TWITTER from "../../../assets/svg/twitter_icon.svg";

export const WES_HERO = {
  title: "WES GARLAND",
  description:
    "The aim of this document is to provide readers with a clear and accessible tips and tricks to WebGPU compute, enabling them to harness its power for their own projects.",
  bgImage:
    "bg-[url(./assets/png/article_backgrounds.png)] bg-cover bg-no-repeat !bg-center",
  image: "",
};

export const WES_SIDE_INFO = {
  date: "July 28, 2023",
  firstName: "Wes",
  lastName: "Placeholder",
  tableOfContents: "Table of Contents",
  aboutTheAuthor: "About the Author",
  authorImg: "/python_monkey/will_pringle.jpg",
  aboutTheAuthorInfo: "Software Developer at Distributive",
  contactLinks: "Contact Links",
  contactLinksList: [
    // Email
    {
      icon: MAIL_ICON,
      displayName: "will@distributive.network",
      link: "mailto:will@distributive.network",
    },
    // LinkedIn
    {
      icon: LINKEDIN_ICON,
      displayName: "LinkedIn",
      link: "https://www.linkedin.com/in/will-pringle/",
    },
    // Github
    {
      icon: GITHUB,
      displayName: "Github",
      link: "https://github.com/wiwichips",
    },
    // Twitter
    {
      icon: TWITTER,
      displayName: "",
      link: "",
    },
    // University Website
    {
      icon: WEBSITE_ICON,
      displayName: "",
      link: "",
    },
    // Personal Website
    {
      icon: WEBSITE_ICON,
      displayName: "",
      link: "",
    },
  ],
  articles: "Articles",
  articleLinks: [
    // {
    //   name: "",
    //   link: "",
    // },
  ],
  keywords: "Keywords",
  keywordsList: [
    // "Mathematics",
    // "Legendre Pairs",
    // "Combinatorics",
    // "Parallel search",
  ],
};

function WesContent() {
  return <div>Hi I'm WesContent!</div>;
}

export default WesContent;
