import React, { useEffect } from "react";
import { DCP_WORKER } from "../../PageContent/DCPWorkers";
import HeroPanel from "../../components/HeroPanel/HeroPanel";
import Margins from "../../components/Margins/Margins";
import WorkerCard from "../../components/WorkerCard/WorkerCard";
import TextImageSplit from "../../components/TextImageSplit/TextImageSplit";
import CTAPanel from "../../components/CTA/CTAPanel";
import CardPanel from "../../components/CardPanel/CardPanel";
import Button from "../../components/Button/Button";
import { Helmet } from "react-helmet";
function DCPWorkers() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content={DCP_WORKER.metadata?.description} />
        <meta name="keywords" content={DCP_WORKER.metadata?.keywords} />
      </Helmet>
      {/* ------------------------------------------------- Hero Panel ------------------------------------------------- */}
      <HeroPanel
        title={DCP_WORKER.hero.title}
        bgImage={DCP_WORKER.hero.bgImage}
        minHeight="!min-h-[700px] !2xl:min-h-[700px]"
        contentClassName="relative bottom-[90px]"
      >
        <div className="w-[100%] xl:w-[66%]">
          <h2 className="h2_hero_style">{DCP_WORKER.hero.description1}</h2>
          <h2 className="h2_hero_style mt-[40px]">{DCP_WORKER.hero.description2}</h2>
        </div>
      </HeroPanel>

      {/* ------------------------------------------------- Worker Types ------------------------------------------------- */}
      <Margins className="">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 justify-center gap-[10px] 3xl:gap-[20px] relative mt-[60px] xl:mt-[-160px]">
          {DCP_WORKER.workers.cards.map((card) => (
            <WorkerCard
              borderCardClassName="w-[100%]"
              cardClassName="m-0 min-w-[240px] w-[100%]"
              iconClassName="w-[100] mt-[29px]"
              content={card}
            >
              <div className="my-[30px] text-center ">
                <p className="p_large_style mb-[20px] font-bold text-[#fff]">
                  {card.descriptionTitle}
                </p>
                <p className="p_standard_style text-[#fff]">
                  {card.description}
                </p>
              </div>
            </WorkerCard>
          ))}
        </div>
      </Margins>
      {/* ------------------------------------------------- Compute Groups ------------------------------------------------- */}
      <div className="get-started flex justify-start flex-col items-center w-[100%] !mt-[68px]">
        <CardPanel
          title={DCP_WORKER.computeGroups.title}
          subHeading={DCP_WORKER.computeGroups.description}
          textBlack={true}
        >
          <div className="w-[90%]">
            <img
              src={DCP_WORKER.computeGroups.img}
              alt="computeGroups"
              className="m-auto"
            />
            <div className="flex flex-col xl:flex-row xl:justify-center gap-[20px] xl:gap-[81px] font-bold my-[40px] w-[100%]">
              {DCP_WORKER.computeGroups.blockTexts.map((item: any, i: any) => (
                <div>
                  <h3 className="h3_style mb-[15px]">{item.title}</h3>
                  <div key={i} className="xl:max-w-[470px] font-normal">
                    {item.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CardPanel>
      </div>
      {/* ------------------------------------------------- The DCP Portal -------------------------------- */}
      <TextImageSplit
        title={DCP_WORKER.dcpPortal.title}
        subHeading={DCP_WORKER.dcpPortal.subheading}
        textBgImage={DCP_WORKER.dcpPortal.textBgImage}
        bgImage={DCP_WORKER.dcpPortal.bgImage}
      >
        <div className="mt-[30px]">
          <p>{DCP_WORKER.dcpPortal.paragraph1}</p>
          <p className="mt-[30px]">{DCP_WORKER.dcpPortal.paragraph2}</p>
          <div className="mt-[30px]  w-[100%]">
            <Button
              url={DCP_WORKER.dcpPortal.button.url}
              text={DCP_WORKER.dcpPortal.button.text}
              isButton={false}
            ></Button>
            {/* <a
              href={DCP_WORKER.dcpPortal.button.url}
              className="bg-[#ffffff33] py-[15px] px-[35px] border-solid border-[#ffffff33] border-[1px]"
              target="_blank"
            >
              {DCP_WORKER.dcpPortal.button.text}
            </a> */}
          </div>
        </div>
      </TextImageSplit>
      {/* ------------------------------------------------- CTA -------------------------------- */}
      <CTAPanel
        title={DCP_WORKER.cta.title}
        ctaText={DCP_WORKER.cta.ctaText}
        ctaTextColour={DCP_WORKER.cta.ctaTextColour}
        subHeading={DCP_WORKER.cta.subHeading}
        ctaBgColour={DCP_WORKER.cta.ctaBgColour}
        bgImage={DCP_WORKER.cta.bgImage}
        href={DCP_WORKER.cta.href}
      ></CTAPanel>
    </>
  );
}

export default DCPWorkers;
