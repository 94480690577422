import React from "react";
import { CardModel } from "../../models/card.model";
import FaqComponent from "../FaqComponent/FaqCard";
import "./FaqPanel.scss";

function FaqPanel({ topic, faqBackground}: any) {
  return (
    <div className={`faq-card-panel ${faqBackground}`}>
      <h1 className="text-white text-center text-[30px] md:text-[35px] pt-[4rem]">
        {topic.title}
      </h1>
      <div className=" flex flex-wrap gap-[2rem] px-[2rem] py-[2rem] justify-center items-center w-[100%]">
        {topic.cards?.map((card: CardModel, key: any) => (
          <FaqComponent
            key={key}
            src={card.src}
            title={card.title}
            description={card.description}
            imageFirst={true}
          />
        ))}
      </div>
    </div>
  );
}

export default FaqPanel;
