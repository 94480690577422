import React from "react";
import "./NumberGlassCard.scss";
import { CardModel } from "../../models/card.model";
import { twMerge } from "tailwind-merge";
export default function NumberGlassCard(card: CardModel) {
  return (
    <div className={twMerge(`number-glass-card-container `, card.divClassName)}>
      {card.src && (
        <img
          src={card.src}
          alt="Icon Number"
          className="number-glass-card-icon"
        />
      )}
      {card.title && (
        <h3 className="h3_style number-glass-card-title">{card.title}</h3>
      )}
      {card.description && (
        <p className="p_standard_style number-glass-card-description">
          {card.description}
        </p>
      )}
      {card.children}
    </div>
  );
}
