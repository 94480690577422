import React from "react";
import parse from "html-react-parser";
import { AnimatePresence, motion } from "framer-motion";

function TabbedPanel({ layoutId, tabs, tabData, tabIndex, setTabIndex }) {
  return (
    <>
      <div className="features-nav flex gap-[2rem] font-bold pt-[.7rem] max-w-[400px] sm:max-w-none overflow-x-scroll sm:overflow-auto">
        {tabs.map((tab, key) => (
          <div className="relative">
            <button
              key={key}
              className={`${
                tabIndex === key ? "text-[#0e94f5]" : ""
              } pr-[1rem] py-[.5rem] w-[110px] sm:w-auto flex justify-start`}
              onClick={() => {
                setTabIndex(key);
              }}
            >
              {tab}
            </button>
            <AnimatePresence>
              {key === tabIndex && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.4, //How long it will take to slide out of frame
                    },
                  }}
                  layoutId={layoutId}
                  className="tab-underline h-[5px] w-[50px] absolute bottom-[0rem]"
                ></motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
      <p className="p_standard_style mt-[1.5rem]">
        {parse(tabData[tabIndex]) || ""}
      </p>
    </>
  );
}

export default TabbedPanel;
