import { ABOUT } from "../../PageContent/About";
import HeroPanel from "../../components/HeroPanel/HeroPanel";
import MentionsBoard from "../../components/Mentions/MentionsBoard";
import CardPanel from "../../components/CardPanel/CardPanel";
import { CardModel } from "../../models/card.model";
import CTA from "../../components/CTA/CTAPanel";
import "./About.scss";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import EmployeeGrid from "../../components/EmployeeGrid/EmployeeGrid";

export default function About() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto", // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={ABOUT.metadata?.description} />
        <meta name="keywords" content={ABOUT.metadata?.keywords} />
      </Helmet>
      {/* ------------------------------------------------- Hero Panel ------------------------------------------------- */}
      <HeroPanel
        bgImage={ABOUT.hero.bgImage}
        title={ABOUT.hero.title}
        description={ABOUT.hero.description}
        // fullHeight={true}
        minHeight="min-h-[60vh]"
      />
      <MentionsBoard />

      {/* ------------------------------------------------- Leadership ------------------------------------------------- */}
      <EmployeeGrid
        title={ABOUT.leadership.title}
        numOfCols={ABOUT.leadership.numOfCols}
        subHeading={ABOUT.leadership.subTitle}
        bgImage={ABOUT.leadership.bgImage}
        leaders={ABOUT.leadership.leaders}
      ></EmployeeGrid>

      {/* ------------------------------------------------- Our Academic Partners ------------------------------------------------- */}
      <div className="partners m-[1.5rem]">
        <CardPanel
          childrenGap="!gap-x-[3vw] lg:!gap-x-[6vw] xl:!gap-x-[8vw] !gap-y-[0rem]"
          title={ABOUT.academicPartners.title}
          textBlack={true}
          parentDivClassName="!pb-[0]"
        >
          {ABOUT.academicPartners.partnerList?.map((card: CardModel, index) => (
            <img key={index} alt="" src={card.src} className=" w-[180px]"></img>
          ))}
        </CardPanel>
      </div>
      {/* ------------------------------------------------- Our Healthcare Partners ------------------------------------------------- */}
      <div className="partners m-[1.5rem]">
        <CardPanel
          childrenGap="!gap-x-[3vw] lg:!gap-x-[6vw] xl:!gap-x-[0vw] !gap-y-[0rem]"
          title={ABOUT.healthcarePartners.title}
          textBlack={true}
          parentDivClassName="!pb-[0]"
        >
          {ABOUT.healthcarePartners.partnerList?.map(
            (card: CardModel, index) => (
              <div className="xl:w-[24%] flex justify-center">
                <img
                  key={index}
                  alt=""
                  src={card.src}
                  className=" w-[180px]"
                ></img>
              </div>
            )
          )}
        </CardPanel>
      </div>
      {/* ------------------------------------------------- Our Operations Partners ------------------------------------------------- */}
      <div className="partners m-[1.5rem]">
        <CardPanel
          childrenGap="!gap-x-[3vw] lg:!gap-x-[6vw] xl:!gap-x-[8vw] !gap-y-[0rem]"
          title={ABOUT.operationPartners.title}
          textBlack={true}
          parentDivClassName="!pb-[0]"
        >
          {ABOUT.operationPartners.partnerList?.map(
            (card: CardModel, index) => (
              <img
                key={index}
                alt=""
                src={card.src}
                className=" w-[180px]"
              ></img>
            )
          )}
        </CardPanel>
      </div>
      {/* ------------------------------------------------- Our Supercluster, Government and Para-governmental Organization Partners ------------------------------------------------- */}
      <div className="partners m-[1.5rem]">
        <CardPanel
          childrenGap="!gap-x-[3vw] lg:!gap-x-[6vw] xl:!gap-x-[8vw] !gap-y-[0rem]"
          title={ABOUT.organizationPartners.title}
          textBlack={true}
          parentDivClassName="!pb-[0]"
        >
          {ABOUT.organizationPartners.partnerList?.map(
            (card: CardModel, index) => (
              <img
                key={index}
                alt=""
                src={card.src}
                className=" w-[180px]"
              ></img>
            )
          )}
        </CardPanel>
      </div>

      {/* ------------------------------------------------- Investors ------------------------------------------------- */}
      {/* <div className="investors">
        <CardPanel
          childrenGap="!gap-[2rem] mx-[0] lg:mx-[2rem] flex-col lg:flex-row items-center"
          title={ABOUT.investors.title}
          subHeading={ABOUT.investors.subHeading}
          bgImage={ABOUT.investors.bgImage}
        >
          {ABOUT.investors.investorInfo?.map((card: CardModel, index) => (
            <IconGlassCard
              divClassName={` lg:!w-[30%] !h-[350px] !min-h-[300px]`}
              imgClassName="!w-[300px] xl:!w-[350px] lg:!pb-0 xl:!pb-[1rem]"
              h1ClassName="!py-[1rem]"
              key={index}
              src={card.src}
              title={card.title}
              description={card.description}
              imageFirst={true}
            />
          ))}
        </CardPanel>
      </div> */}

      {/* ------------------------------------------------- CTA ------------------------------------------------- */}
      <CTA
        title={ABOUT.cta.title}
        subHeading={ABOUT.cta.subHeading}
        ctaText={ABOUT.cta.ctaText}
        ctaBgColour={ABOUT.cta.ctaBgColour}
        ctaTextColour={ABOUT.cta.ctaTextColour}
        bgImage={ABOUT.cta.bgImage}
        href={ABOUT.cta.href}
      ></CTA>
    </>
  );
}
