import NUM_1 from "../assets/svg/security-card-1.svg";
import NUM_2 from "../assets/svg/security-card-2.svg";
import NUM_3 from "../assets/svg/security-card-3.svg";
import SEVENTY from "../assets/svg/percents_70.svg";
import EIGHTY from "../assets/svg/percents_80.svg";
import POINTS from "../assets/png/data viz_3000_to_5000.png";
import FOUR_EIGHT from "../assets/svg/percents_4x8.svg";
import OSLER_CALENDAR from "../assets/png/osler-calendar.png";
import OSLER_LOGO from "../assets/svg/osler-logo.svg";
import DATA_DRIVEN from "../assets/svg/data-driven.svg";
import SCHEDULE from "../assets/svg/schedule.svg";
import RESULTS from "../assets/svg/results.svg";
import REPORTS from "../assets/svg/reports.svg";
import quinte_health from "../assets/svg/qhc.svg";
import grey_bruce from "../assets/svg/grey-bruce.svg";
import chatham_kent from "../assets/svg/chatham-kent.svg";
import kingston_health from "../assets/svg/kingston-health.svg";
import thunder_bay_regional from "../assets/svg/thunder-bay-regional.svg";
export const OSLER = {
  hero: {
    title: "Block Schedule Creation <br /> with Surgical Precision",
    bgImage:
      "bg-[url(./assets/png/background.png)] !bg-left bg-cover bg-no-repeat",
    logo: OSLER_LOGO,
    calendar: OSLER_CALENDAR,
    description:
      "Increasing operating room efficiency helps patient outcomes, reduces ICU patient diversions, and decreases surgical backlogs.",
  },
  results: {
    stats: [
      {
        src: SEVENTY,
        description:
          "Up to 70% of diversions from the ICU are associated with variability in the scheduled caseload",
      },
      {
        src: EIGHTY,
        description:
          "80% of variability from the OR is due to variations in the elective caseload – not emergencies, cancellations or add-ons",
      },
      {
        src: FOUR_EIGHT,
        description:
          "Digitally optimized block schedules can increase surgical throughput by 4-8%",
      },
      {
        src: POINTS,
        description:
          "There are up to 5,000 decision points to consider when generating a block schedule",
      },
    ],
  },
  hospitals: {
    title: "Built with Hospitals for Hospitals",
    subTitle:
      "Surgical block scheduling affects the whole hospital. Osler starts with your data, builds a schedule that fits your requirements, provides measurable ROI, and creates reports for continued improvements.",
    cards: [
      {
        title: "DATA DRIVEN",
        src: DATA_DRIVEN,
        description:
          "Historical surgical data trains Osler to build custom schedules with easy constraint/requirement integration.",
      },
      {
        title: "SCHEDULE",
        src: SCHEDULE,
        description:
          "Custom schedules that prioritize surgical smoothing are created in minutes instead of weeks.",
      },
      {
        title: "RESULTS",
        src: RESULTS,
        description:
          '<ul className="list-disc"><li>Reduce ICU patient diversions</li> <li>Improve schedule flexibility</li> <li>Increase surgical throughput</li></ul>',
      },
      {
        title: "REPORTS",
        src: REPORTS,
        description:
          "View anytime insights about surgical schedule performance that actually help to improve processes.",
      },
    ],
  },
  introducingOsler: {
    title: "Introducing Osler",
    subHeading:
      "Learn how Osler improves efficiency by utilizing surgical block optimization",
    video: "https://www.youtube.com/embed/hNWHompt3VY?si=NcJXfHsA-uZV5U9T",
  },
  features: {
    title: "Features",
    subHeading:
      "The arrangement of blocks changes the performance of a surgical schedule.",
    textBgImage: "bg-[url(./assets/png/content-tabs-background.png)]",
    bgImage:
      "bg-[url(./assets/png/osler-tabs-1.png)] !bg-cover !bg-bottom bg-no-repeat",
    tabs: ["WORKFLOW", "PREDICTION", "INSIGHT"],
    tabData: [
      "Draws data automatically from existing data/record systems.<br/><br/>Allows staff to create schedules in minutes.<br/><br/>Consistent format of schedules allows for easy sharing with surgical teams.",
      "Osler predicts the performance indicators for generated schedules - allowing for easier planning and adaptability.<br/><br/>Schedulers can see the impact of schedule changes directly.",
      "Osler presents analytics, trends, and insights on surgical performance any time you need. Filter by time, procedure service, surgeon and more. Use the pulse of up to date information to transform department strategy.",
    ],
  },
  benefits: {
    title: "Benefits",
    subHeading:
      "Over 5000 variables affect the patient outflow, OR utilization, and surgical throughput.",
    textBgImage:
      "bg-[url(./assets/png/content-tabs-background.png)] !bg-cover !bg-bottom bg-no-repeat",
    bgImage:
      "bg-[url(./assets/png/osler-tabs-2.png)] !bg-cover !bg-bottom bg-no-repeat",
    tabs: ["CREATED IN MINUTES", "LOAD BALANCED", "OPTIMIZED"],
    tabData: [
      "Provides for greater flexibility in scheduling.<br/><br/>Allows for rapid adaptation to changing circumstances.",
      "Smooth patient outflow adjusted to hospital size and staff requirements.<br/>Smoothing peaks prevents ICU diversions.",
      "Optimized block schedules do more with less. They are more predictive, leading to fewer case diversions, and they increase throughput, reducing surgical backlogs.",
    ],
  },
  security: {
    title: "Security",
    subTitle:
      "Osler fits in without compromising what is important: the security of the patient, the hospital, and health information",
    bgImage:
      "bg-[url(./assets/png/security-background.png)] !bg-cover !bg-bottom bg-no-repeat",
    cards: [
      {
        title: "NO PERSONALLY IDENTIFIABLE INFORMATION (PII)",
        description:
          "Osler trains its sophisticated model on historical surgical data and hospital requirements and constraints without the need for any patient information.",
        src: NUM_1,
      },
      {
        title: "NO DATA LEAVES THE PREMISE",
        description:
          "Osler deploys in-hospital and integrates with electronic record systems to prevent any data leaving the building.",
        src: NUM_2,
      },
      {
        title: "ACCESS CONTROLS",
        description:
          "Set user permissions to view schedules or reporting data, and to generate the schedules - preventing bad-access.",
        src: NUM_3,
      },
    ],
  },
  partners: {
    title: "Hospitals Implementing Osler",
    subTitle:
      "Distributive is grateful to help improve scheduling alongside our health authority partners",
    partnerList: [
      {
        src: quinte_health,
      },
      {
        src: grey_bruce,
      },
      {
        src: chatham_kent,
      },
      {
        src: kingston_health,
      },
      {
        src: thunder_bay_regional,
      },
    ],
  },

  cta: {
    title: "Arrange Your Demo Today",
    subTitle:
      "Discover how data-driven OR scheduling algorithms can smooth patient outflows, maximize throughput and reduce surgical backlogs.",
    bgImage: "bg-[url(./assets/png/demo-button-background.png)]",
    ctaText: "Schedule a Demo Today",
    href: "mailto:osler@distributive.network",
  },
};
