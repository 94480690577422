import React from "react";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import styles from "./ArticleCard.module.scss";
function ArticleCard({ content, cardClassName, borderCardClassName }: any) {
  return (
    <Link
      to={content.readMore.href}
      className={twMerge(
        `bg-[#D9D9D933] flex flex-col justify-center items-center p-[10px] 3xl:p-[20px] w-[100%] max-w-[475px] h-[100%] xl:h-[603px] hover:scale-[1.01] transition-all duration-75 ease-linear`,
        borderCardClassName
      )}
    >
      {/* Article Image */}
      <img
        src={content?.image}
        alt="article"
        className=" max-h-[300px] xl:max-h-[261px] w-[100%] object-cover object-center"
      />

      {/* Article Content */}
      <div
        className={twMerge(
          `os-content bg-[#0D2438] text-[#fff] flex flex-col w-[100%] h-[100%] justify-between items-start xl:items-center px-[30px] md:px-[50px] py-[30px] text-center `,
          cardClassName
        )}
      >
        <div className="p_small_style text-[#e2e2e2] flex flex-col items-start justify-start text-left gap-[16px] pb-[20px]">
          <p>{content.date}</p>
          <h3 className="h2_style text-[white] font-bold">{content.title}</h3>
          <p className="max-w-[600px]">{content.description}</p>
          <Link
            to={content.readMore.href}
            className={`p_standard_style ${styles.article_read_more}`}
          >
            {content.readMore.text}
          </Link>
        </div>
      </div>
    </Link>
  );
}

export default ArticleCard;
