import React from "react";
import "./FrameworkBanner.scss";
import { FRAMEWORK } from "../../PageContent/Framework";
import parse from "html-react-parser";

function FrameworkBanner() {
  let components = FRAMEWORK.banner;
  return (
    <div className="text-center py-10 px-[1rem] ">
      <h2 className="banner-title">
        {parse(components.title as string)}
      </h2>
      <p className="p_large_style pt-[2rem]">{components.subtitle}</p>
    </div>
  );
}

export default FrameworkBanner;
