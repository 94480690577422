import React from "react";
import NewsMention from "./NewsMention";
import NewsStories from "../../../PageContent/MentionContent/NewsStories";
import { NewsStory } from "../../../models/news-story.model";
import { ABOUT } from "../../../PageContent/About";

export default function News() {
    const newsComponents = NewsStories();

    return (
        <>
            <div className="news-container">
                <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40"/>
                {newsComponents.slice(-(ABOUT.news.numOfMentions)).reverse().map((news: NewsStory, key) => (
                    <>
                        <NewsMention key={key} url={`/news/${news.id}`} date={news.date} headline={news.headline}></NewsMention>
                        <div className="h-[1px] w-full bg-[#D9D9D9] bg-opacity-40"/>
                    </>
                ))}
            </div>
        </>
    )
}