import React from "react";
import { MarginsModel } from "../../models/margins.model";
import "./margins.scss";
import { twMerge } from "tailwind-merge";
function Margins({ className, children, disableMargins }: MarginsModel) {
  return (
    <div
      className={twMerge(
        `margins-container ${disableMargins ? "" : "layout-container"}  `,
        className
      )}
    >
      {children}
    </div>
  );
}

export default Margins;
